<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px; ">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px; background-color:rgb(242,242,242);margin:0px;">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/products" class="removeHrefStyle">產品介紹</router-link> > <span class="currentPageColor">自動切換開關/TVSS/遙控切換開關</span> </span>
    </div>
    <div class="row col-12" style="margin-top:25px; margin:0px;">
<div class= "row col-10 offset-1">
      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct1" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/001_300.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              <span>300系列自動切換開關</span>
            </div>        
          </div>
        </router-link>
      </div>

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct2" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/002_4000_7000.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              4000/7000系列自動切換開關
            </div>        
          </div>
        </router-link>
      </div>

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle thirdItemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct3" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/003_4000_7000_CTTS.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              4000/7000系列閉路式自動切換開關CTTS
            </div>       
          </div> 
        </router-link>
      </div>



      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct4" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/004_7000.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              <span>7000系列旁路切換開關</span>
            </div>        
          </div>
        </router-link>
      </div>

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct5" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/005_TVSS.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              TVSS突波吸收器
            </div>        
          </div>
        </router-link>
      </div>

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle thirdItemStyle" style="border: solid 20px rgb(242,242,242); padding:20px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct6" class="removeHrefStyle">
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../img/PRODUCTS/04_ATS_TVSS_CTTS/006_RCSW.png" style="width:100%;">
          </div>
          <div class="col-12" style="height:40%; padding:0px; width:100%;">
            <div class="col-12" style="height:10%;padding:0px;">
              <img class="ascoStyle" src="../../../img/GENERIC/LOGO/asco.png">
            </div>
            <div class="col-12 subtitleStyle" style="height:10%;padding:0px;">
              遙控切換開關
            </div>       
          </div> 
        </router-link>
      </div>


</div>
     
    </div>
    <div class="col-12">&nbsp;</div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.SecondItemStyle {
  margin-top: 20px;
}
.currentPageColor {
  color: rgb(247,150,70);
  text-decoration: underline;
}
@media (max-width: 1199px) {
  .thirdItemStyle {
    margin-top: 20px;
  }
}

@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}

.removeHrefStyle {
  color: #3c3c3c; 
  text-decoration: none !important;
}

@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 991px) {
  .itemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  }  
}
</style>