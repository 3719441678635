<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../img/PROJECT/title-project-1000＊200.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div class="setCenter bannerStyle" style="text-align: center;">
        <div class="projectTitle2" style="background-color:rgba(255,255,255,0.2);padding:0px 20px;">
          <div style="text-align: center "><span class="cntTitle2">SOLCON低壓緩衝啟動器<br>在台銷售部分實績表</span></div>
        </div>
      </div>       
    </div>    
  </div>  
  <div class="row contentStyle" style="margin-top:25px;">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem;"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/project" class="removeHrefStyle">工程實績</router-link> > <span class="currentPageColor">SOLCON低壓緩衝啟動器在台銷售部分實績表</span> </span>
    </div>
    <div class="row col-12 tableStyle" id="ASCO_TVSSContent" style="margin-top:25px; margin:0px; padding-left:10%;padding-right:10%;"></div>
    <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
  </div> 
</template>
 
<script>

const bgColor1 = 'rgb(224, 229, 240)';
const bgColor2 = 'rgb(242, 244, 248)';

function createTable(l, colCount){
  
  var gridHtml = '';
  var bgColor = bgColor1;
  var marginTop = '0px';
  var marginBottom = '0px';
  var marginLeft = '0px';
  var group = 0;
  for(var i=0; i< l.length; i++){


    if ( parseInt((i/colCount))%2 == 0)
      bgColor = bgColor1;
    else
      bgColor = bgColor2

    if ( parseInt((i/colCount))%10 == 0)
      marginTop = '20px';      
    else
      marginTop = '0px';
      
    group = parseInt(i/colCount*10);
    if ( (i-group*colCount) < (colCount-1) )
      marginBottom = '-1px'
    else
      marginBottom = '0px'

    if (i%colCount != 0)
      marginLeft = '-1px';
    else 
      marginLeft = '0px'
      

    gridHtml += '<div class="col-lg-3 col-md-4 col-6" style="align-items: center;display: flex;text-align:left;height:60px; border: solid 1px rgb(79,129,189); background-color: '.concat(bgColor, '; margin-left: ', marginLeft ,'; margin-bottom: ', marginBottom , '; margin-top: ', marginTop , '">', l[i], '</div>');
  }
  
  document.getElementById("ASCO_TVSSContent").innerHTML = gridHtml;
}

export default {
  setup() {
  const prjList = ["高雄東帝士大樓","六輕煉油廢水處理廠","彰濱工業區","中國醫藥學院","雲林科技大學"," 中央大學","高雄師範大學","超豐電子","光磊電子","中華映管","台中郵政總局","中華電信台北長途通信大樓","新竹第五信用合作社","中壢遠東百貨","月眉休閒育樂世界","板橋亞東醫院","台南安平抽水站","屏東放孛溝抽水站","遠傳台中機房","台北京華城","高雄亞企中心","中鋼","台塑煉油廠","台塑乳化粉廠","台塑IEM廠","台塑鋁電池廠","台北國際學舍大樓","台中廣三SOGO","台北遠雄大樓","永和SOGO","台北新光三越百貨","台北國際金融中心","台北統一國際大樓","南山人壽總公司大樓","台北國貿大樓","板橋亞東技術學院","中華映管-八德廠","墾丁六福莊渡假旅館","台南香格里拉遠東大飯店","麗寶樂園-馬拉灣","花蓮市遠東百貨","台中愛買吉安店","台北克緹國際大樓","台北遠東通訊園區TPKA研發大樓","台北統一國際大樓","板橋遠東百貨購物中心","台中遠東百貨購物中心","台北大倉久和大飯店","板橋亞東紀念醫院第二院區","板橋遠東百貨二期辦公大樓","台北資訊園區","板橋愛買貴興店"
];
  return {
    prjList,
  };
  },
  data() {
    return {
      myWidth: window.innerWidth + 'px',
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  if (window.innerWidth >= 992)
    createTable(this.prjList, 4);
  else if (window.innerWidth < 992 && window.innerWidth >= 768)
    createTable(this.prjList, 3);
  else if (window.innerWidth < 768 && window.innerWidth >= 576)
    createTable(this.prjList, 2);  
  else if (window.innerWidth < 576)
    createTable(this.prjList, 2);     

  const self = this
    window.onresize = () => {
      if (window.innerWidth >= 992)
        createTable(this.prjList, 4);
      else if (window.innerWidth < 992 && window.innerWidth >= 768)
        createTable(this.prjList, 3);
      else if (window.innerWidth < 768 && window.innerWidth >= 576)
        createTable(this.prjList, 2);        
      else if (window.innerWidth < 576)
        createTable(this.prjList, 2);                 

      return (() => {
        self.myWidth = window.innerWidth + 'px'
      })()
    }

  },//edn 

};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 55%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.tableStyle {
  font-size: 1.2rem;
  text-align: center;
}
.bannerStyle {
  margin-top:-2%;
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
@media (max-width: 1140px) {
  .contentStyle {
    font-size: 1.2rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2rem;
  }
   .iconStyle {
    padding-top: 20px;
   }
  .text-block2 {
    position: absolute;
    top: 20%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.3rem;
  }   
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
  .tableStyle {
  font-size: 1rem;
}
}
@media (max-width: 576px) {
  .tableStyle {
  font-size: 0.9rem;
  text-align: center;
}
.bannerStyle {
  margin-top:-5%;
}
}
.engTitle {
  font-family: "Cambria", sans-serif !important;
  font-size:2.6rem;
}
.ctnTitle {
  font-size:2.2rem;
}
</style>