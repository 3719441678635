<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px; ">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/products" class="removeHrefStyle">產品介紹</router-link> > <router-link to="/products/Earth_Leakage_Relay" class="removeHrefStyle">漏電檢出電驛</router-link> > <span class="currentPageColor">ELR3C 漏電檢出電驛 </span></span>
    </div>

    <div class="row col-12" style="margin-top:25px; margin:20px 0px 0px 0px;">
      <div class= "row col-md-4 offset-md-1 col-10 offset-1 setCenter itemStyle" style="border: solid 0px #ccc; padding:20px; padding-top:0px;">
        <div>
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../../img/PRODUCTS/05_Earth_Leakage_Relay/05_introduce/001-1_ELR3C.png" style="width:100%;">
          </div>
        </div>
      </div>

      <div class= "row col-md-6 offset-md-1 col-10 offset-1 setCenter itemStyle" style="padding:10px;padding-top:30px;">
        <div class="setCenter" style="height:56px;padding-left:0px;">
          <div class="row col-12" style="padding:0px;margin:0px">
            <div class="col-12" style="height:10%;padding:0px;">
              <img src="../../../../img/GENERIC/LOGO/FANOX.png" style="width:280px;">
            </div>              
          </div>
        </div> 


        <div style="height:90%; padding:0px; padding-top:0px;margin-top:-1%">
          <div class="col-12" style="padding:0px; width:100%;">
            <span class="productsTitle" style="font-weight:700; padding-top:20px;;height:70%">ELR3C 漏電檢出電驛</span>          
          </div>
          <div class="col-12" style="padding:0px; width:100%;padding-top:25px">
            <span>      
            ●&nbsp;檢測電流：0,025A 到 25A 可調
            <br>●&nbsp;跳脫延遲：0,02s 到 5s 可調
            <br>●&nbsp;模組化尺寸，DIN軌道式安裝
            <br>●&nbsp;控制電壓：適用115V - dc, ac、230-400V - ac 
            <br>●&nbsp;跳脫復歸：自動手動可調
            <br>●&nbsp;指示燈：ON 及TRIP 指示
            <br>●&nbsp;按鈕：TEST 及 RESET
            <br>●&nbsp;輸出接點：1組ab接點,5A-250V
            <br>●&nbsp;標準：IEC 41-1, IEC 255, VDE 0664
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; EN 50081-1, EN 50082-2
            <br>
            <br>[附註]&nbsp;產品即將於2022年底停產，屆時將只剩庫存可銷售</span>

          </div>
        </div>  
        <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>   
        
      </div>

    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.SecondItemStyle {
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .thirdItemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}

.removeHrefStyle {
  color: #3c3c3c; 
  text-decoration: none !important;
}
.itemStyle {
  margin-top: 20px;
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 991px) {
  .itemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
.subtitleStyle {
   font-size: 1.3rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}    
}
</style>