// import { createApp } from "vue";
import { createApp } from 'vue/dist/vue.esm-bundler';
import App from "./App.vue";
import { router } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
    startEvent: 'DOMContentLoaded',
    once: false,
    duration: 1000,
    easing: 'ease-in-sine',
    delay: 400,
  });

import  VueGoogleMaps from '@fawmi/vue-google-maps'

library.add(fas);

const app = createApp(App);
app.use(router);
app.use(AOS.init());

router.afterEach((to, from, next)=>{
    console.log(to+from+next);
    window.scrollTo(0, 0);
});

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA2-pXc3jXjXjAgy8ypVtj5hm3QFaBWMSQ',
    },
})

app.mount("#app");
