<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px; ">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/products" class="removeHrefStyle">產品介紹</router-link> > <router-link to="/products/LowVoltageDistribution" class="removeHrefStyle">低壓配電及電控產品</router-link> > <span class="currentPageColor">安全開關 </span></span>
    </div>

    <div class="row col-12" style="margin-top:25px; margin:20px 0px 0px 0px;">
      <div class= "row col-md-4 offset-md-1 col-10 offset-1 setCenter itemStyle productImg" style="border: solid 0px #ccc; padding:20px; padding-top:0px;">
        <div>
          <div class="col-12 productStyle" style="height:100%; padding:0px; width:100%;">
            <img src="../../../../img/PRODUCTS/02_Low_Voltage_Distribution_and_Control_Products/001_introduce/003-1_Safety_Switch.png" style="width:105%;">
          </div>
        </div>
      </div>

      <div class= "row col-md-6 offset-md-1 col-10 offset-1 setCenter itemStyle" style="padding:10px;padding-top:30px;">
        <div class="setCenter" style="height:56px;padding-left:0px;">
          <div class="row col-12" style="padding:0px;margin:0px">
            <div class="col-12" style="height:10%;padding:0px;">
              <img src="../../../../img/GENERIC/LOGO/Square_D_Logo.png" style="width:280px;">
            </div>              
          </div>
        </div> 
        
        <div class="logoPos" style="height:90%; padding:0px; padding-top:0px;">
          <div class="col-12" style="padding:0px; width:100%;">
            <span class="productsTitle" style="font-weight:700; padding-top:10px;;height:70%">安全開關</span>    
          </div>
          <div class="col-12" style="padding:0px; width:100%;padding-top:25px">
            <span>                          

            安全開關類型
            <br>●&nbsp;通用型安全開關
            <br>●&nbsp;重責型安全開關
            <br>●&nbsp;雙投型安全開關

            <br><br>系統電壓：240VAC，600VAC	
                    
            <br><br>額定電流 				
            <br>●&nbsp;通用型： 30A、60A、100A、200A、400A、800A
            <br>●&nbsp;重責型： 30A、60A、100A、200A、225A、400A、600A、800A、1200A
            <br>●&nbsp;雙投型： 30A、60A、100A、200A、600A

            <br><br>極數
            <br>●&nbsp;通用型： 1P、2P、3P
            <br>●&nbsp;重責型： 2P、3P、4P、6P
            <br>●&nbsp;雙投型： 2P、3P、4P、6P
                    
            <br><br>防護等級
            <br>●&nbsp;通用型：NEMA 1、NEMA 3R
            <br>●&nbsp;重責型：NEMA 1、NEMA 12K、NEMA 3R、5、12、NEMA 4、4X、5 (不鏽鋼 304)、NEMA 4、4X、5 (不鏽鋼 316)
            <!-- <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  -->
            <br>●&nbsp;雙投型：NEMA 1、NEMA 12K、NEMA 3R、5、12、NEMA 4、4X、5 (不鏽鋼 304)</span>
            <!-- <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  -->
          </div>
          <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
          <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
        </div>        
      </div>

    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.SecondItemStyle {
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .thirdItemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}

.removeHrefStyle {
  color: #3c3c3c; 
  text-decoration: none !important;
}
.itemStyle {
  margin-top: 20px;
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 991px) {
  .itemStyle {
    margin-top: 20px;
  }
}
.productStyle {
  padding-bottom:30% !important;
}
.productImg {
  margin-top:-5%;
}
.logoPos {
  margin-top: -6%;
}
@media (max-width: 767px) {
.logoPos {
  margin-top: -10%;
}  
.productStyle {
  padding-bottom:0% !important;
}  
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
.subtitleStyle {
   font-size: 1.3rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}    
.productImg {
  margin-top:0%;
  margin-bottom:-10%;
}
}
</style>