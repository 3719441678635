<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../img/PROJECT/title-project-1000＊200.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div class="setCenter bannerStyle" style="text-align: center;">
        <div>
          <div style="text-align: center "><span class="cntTitleMain">工程實績</span><br><span class="engTitle">Product Project References</span></div>
        </div>
      </div>      
    </div>   
  </div>  
  <div class="row contentStyle" style="padding-top:25px; background-color:rgb(242,242,242)">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <span class="currentPageColor">工程實績 </span></span>
    </div>
    <div class="row col-10 offset-1" style="margin-top:25px;">

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-突波.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >ASCO TVSS突波吸收器<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <div>
              <router-link to="/project/ASCO_TVSS" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
                <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-高低分相式匯流排.jpeg" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >MGC高低壓分相式匯流排<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/MGC" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-SQUARED_BUSWWAY.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >SQUARE D BUSWAY<br>(匯流排槽設備)<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/SQUARE_D_BUSWAY" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/ASCO_ATS_CTTS及發電機併聯盤.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;padding-right:6px" >ASCO ATS/CTTS及發電機併聯盤<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/ASCO_ATS_CTTS" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-安全開關.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >SQUARE D 安全開關<br>(SAFETY SWITCH)<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/SQD" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-中壓緩衝啟動器.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >SOLCON中壓緩衝啟動器<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/SOLCON_MID" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-低壓緩衝啟動器.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >SOLCON低壓緩衝啟動器<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/SOLCON_LOW" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-SQUARED電磁開關.png" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >SQUARE D電磁開關<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/SQUARE_D" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 row" style="padding:20px;margin:0px">
        <div class="col-xl-7 col-12" style="padding-right:0px;padding-left:0px; background-color:#fff">
          <img src="../../img/PROJECT/實績-TECHNIBUS中低壓BUSDUCT.jpg" style="width: 100%">
        </div>
        <div class="col-xl-5 col-12 row setCenter projectTitle" style="margin:0px;background-color:#fff">
          <div class="col-12 setCenter contentStyle itemStyle" style="text-align: center; margin: 0px;" >TECHNIBUS 中低壓 BUSDUCT<br>在台銷售部分實績表</div>
          <div class="col-12 setCenter iconStyle itemStyle2">
            <router-link to="/project/TECHNIBUS" class="removeHrefStyle setCenter btnPos" style="border-radius: 999px; background-color: rgb(0,160,233); width:120px; height:40px; ">
              <span class="fa-solid fa-circle-chevron-right" style="color: white"></span><span style="color: white">&nbsp;更多</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class= "col-md-6 col-12 setCenter" style="padding:20px;">
        <!-- <div>
          <img src="../../img/PROJECT/borderBox.svg" style="width: 100%">
        </div> -->
        <div class="box setCenter textBlock3" style="height:100%;color: rgb(0,104,183)">因實績資料眾多，如果您想知道更多，可以直接聯繫我們。</div>

       <!-- <div class="box gradient-border"></div> -->
          <!-- <img src="../../img/PROJECT/borderBox.svg" style="width:100%"> -->
          <!-- <div class="text-block2" style="width:100%; color: rgb(0,104,183)">
            <div class="borderLeft" style="text-align: center "></div>
          </div>    
        </div>       -->    
      </div>

    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
<style>
.btnPos {
  margin-top: 0%;
}
@media (max-width: 1199px) {
  .btnPos {
    margin-top: 0%;
  }
}
</style>


<style scoped>
.mainDiv {
  display: flex;
}
.box {
  background-image: linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 4px 20%, 8% 4px, 4px 20%, 8% 4px, calc(100% - 8px) calc(100% - 8px);
  background-position: left top, left top, right bottom, right bottom, 4px 4px;
  
  /* height: 160px; */
  width: 100%;
  margin: 20px;
}

.gradient-border {
  background-image: linear-gradient(red, purple),
                    linear-gradient(to right, purple, red),
                    linear-gradient(to bottom, purple, red),
                    linear-gradient(to left, purple, red),
                    linear-gradient(steelblue, steelblue);
}
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.textBlock3 {
  font-size:1.6rem;
  padding:60px;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}

.contentStyle {
  font-size: 1.2rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.currentPageColor {
  color: rgb(247,150,70);
  text-decoration: underline;
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
.textBlock3 {
  font-size:1.6rem;
  padding:60px;
}  
}
@media (max-width: 1140px) {
  .contentStyle {
    font-size: 1.2rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2rem;
  }
   .iconStyle {
    padding-top: 0px;
   }
  .text-block2 {
    position: absolute;
    top: 20%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.3rem;
  }  
  .textBlock3 {
  font-size:1.4rem;
  padding:60px;
}
.box {
  background-image: linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 4px 10%, 10% 4px, 4px 10%, 10% 4px, calc(100% - 8px) calc(100% - 8px);
  background-position: left top, left top, right bottom, right bottom, 4px 4px;
  
  /* height: 160px; */
  width: 100%;
  margin: 20px;
}   
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
.projectTitle {
  padding:0px;
}

.itemStyle {
  margin-top: 15% !important;
  height: 30%
}

.itemStyle2 {
  margin-top: -10%;
  height: 40%;
}

@media (max-width: 1199px) {
  .projectTitle {
  padding:20px;
}  
.itemStyle {
  height: 50%;
  margin-top: 0% !important;
}

.itemStyle2 {
  height: 40%;
  margin-top: 5% !important;
}
}


@media (max-width: 767px) {


  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 

.box {
  background-image: linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(rgb(74,125,186), rgb(74,125,186)),
                    linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 4px 20%, 12% 4px, 4px 20%, 12% 4px, calc(100% - 8px) calc(100% - 8px);
  background-position: left top, left top, right bottom, right bottom, 4px 4px;
  
  /* height: 160px; */
  width: 100%;
  margin: 20px;
}  
  .textBlock3 {
  font-size:1.1rem;
  padding:30px;
}
}
.engTitle {
  font-family: "Cambria", sans-serif !important;
  font-size:2.6rem;
}
.ctnTitle {
  font-size:2.2rem;
}
</style>