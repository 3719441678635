<template>
  <div class="wrap">
    <header id="header" class="header" style="z-index:1000">
        <div class="col-lg-4 col-2 logoBar" style="z-index:9999;">
          <router-link to="/home">
            <img class="logo" id="logoBar" src="./img/GENERIC/LOGO/FPE_logo.png"/>
          </router-link>
        </div>      
      <nav id="nav" class="nav row" style="padding: 0px; margin:0px;">
        <div class="col-12 menuBar">
          <div class="item setCenter mainDiv mainTitleBox hideMenuBar" style="z-index:99999; height:80px;">
            <!-- <router-link to="/home" style="text-align: left; width:110%" @click="handleHamburger">
              <img class="hambugerLogo" id="logoBar" src="./img/GENERIC/LOGO/FPE_logo.png"/>
            </router-link> -->
          </div>          
          <div class="item setCenter mainDiv mainTitleBox">
            <router-link to="/home" style="text-align: center" @click="handleHamburger"
              >首頁<br />HOME</router-link
            >
          </div>
          <div class="item setCenter mainDiv mainTitleBox">
            <router-link to="/about" style="text-align: center" @click="handleHamburger"
              >公司介紹<br />ABOUT US</router-link
            >
          </div>

          <div class="item setCenter mainDiv mainTitleBox">
            <span class="itemGroup itemGroupStyle">
              <div
                class="setCenter"
                style="text-align: center;"
                @click="handleSubMenu"
              >
                <router-link to="/products" class="removeHrefStyle" @click.stop="handleHamburger">產品介紹<br />PRODUCTS&nbsp;&nbsp;&nbsp;</router-link>
                <div class="moreProducts" style="color: #000 !important">&nbsp;<span class="fa-solid fa-caret-down" ></span>&nbsp;</div>
                
              </div>
              
              <div class="subItem subItemPos" id="subItem" style="text-align: center;">
                <router-link to="/products/BusSystem" @click="handleHamburger">匯流排系統</router-link>
                <router-link to="/products/LowVoltageDistribution" @click="handleHamburger"
                  >低壓配電及電控產品</router-link
                >
                <router-link to="/products/SoftStarter" @click="handleHamburger">緩衝啟動器</router-link>
                <router-link to="/products/ATS_TVSS_CTTS" @click="handleHamburger">自動切換開關</router-link>
                <router-link to="/products/Earth_Leakage_Relay" @click="handleHamburger"
                  >漏電檢出電驛</router-link
                >
                <router-link to="/products/Meter" @click="handleHamburger">電錶</router-link>
              </div>
            </span>
          </div>

          <div class="item setCenter mainDiv mainTitleBox">
            <router-link to="/project" style="text-align: center" @click="handleHamburger"
              >工程實績<br />PROJECTS</router-link
            >
          </div>
          <div class="item setCenter">
            <router-link to="/contact" style="text-align: center" @click="handleHamburger"
              >聯絡我們<br />CONTACT</router-link
            >
          </div>
        </div>
      </nav>
    </header>
    <div class="hamburger" @click="handleHamburger" style="z-index:1000">
      <span id="line1"></span>
      <span id="line2"></span>
      <span id="line3"></span>
    </div>

  <router-view v-slot="{ Component }" :key="$route.fullPath">
    <div>
      <component :is="Component" :key="$route.fullPath" />
    </div>
  </router-view>

    <div class="footer row borderSE footerStyle" style="background-color: #353535; padding: 25px 0px 25px 0px; margin: 0px 0px 0px 0px; ">
      <div class="row col-xl-4 col-md-6 col-12" style="padding:0px;margin:0px">
        <div class="col-11 offset-1" style="font-size: 1.1rem; text-align: left; color: rgb(52,142,166); font-weight: 700;padding: 0px;">
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FULL POWER ENERGY CO., LTD.</div>
        </div> 
        <div class="col-11 offset-1" style="font-size: 1.3rem; text-align: left; color: #fff">
          <div style="font-weight: 700;">&nbsp;<span style="color: rgb(47,118,136);">▍</span>第一電氣設備股份有限公司</div>
        </div>
        <div class="row col-11 offset-1 " style="padding: 0px;padding-top:10px;  font-size: 1rem; color: #fff; font-weight: 200; text-align:left; text-align:left;">
          <div>
            <!-- <router-link to="/about" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="fa-solid fa-play"></span>&nbsp;公司介紹&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
            <router-link to="/products" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span></span><span class="fa-solid fa-play"></span>&nbsp;產品介紹</router-link> -->
            <router-link to="/about" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="my-list"><li><span class="fa-solid fa-play"></span>&nbsp;公司介紹</li></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
            <router-link to="/products" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span></span><span class="my-list"><li><span class="fa-solid fa-play"></span>&nbsp;產品介紹</li></span></router-link>            
          </div>
        </div>   
        <div class="row col-11 offset-1 " style="padding: 5px 0px; font-size: 1rem; color: #fff; font-weight: 200; text-align:left; text-align:left;">
          <div>
            <router-link to="/project" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="my-list"><li><span class="fa-solid fa-play"></span>&nbsp;工程實績</li></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
            <router-link to="/contact" style="color: #fff !important; text-decoration: none !important; padding: 0px;"><span></span><span class="my-list"><li><span class="fa-solid fa-play"></span>&nbsp;聯絡我們</li></span></router-link>                        
          </div>
        </div>   

      </div>

      <div class="row col-lg-4 col-md-6 col-12 setCenter topTextFooter">    
        <div class="col-8 conic" style="font-size: 1.2rem; color: #fff">Best Choice / Service First<br>嚴選設備  服務第一</div>
        <div class="col-12" style="font-size: 0.6rem; color: #000">Copyright © 2022 FPE Co., Ltd. All rights reserved.</div>
      </div>

      <div class="row col-xl-4 col-md-6 col-12 contactDiv" style="padding:0px;margin:0px">
        <div class="col-11 offset-1" style="font-size: 1.3rem; text-align: left; color: #fff">
          <div style="font-weight: 700;">&nbsp;<span style="color: rgb(47,118,136);">▍</span>聯絡資訊</div>
        </div>        

        <div class="row col-11 offset-1 " style="padding: 0px; font-size: 1rem; color: #fff; font-weight: 200; text-align:left;">          
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa-solid fa-map-location-dot"></span>&nbsp;248 新北市五股區五權二路37號</div>
        </div> 
        <div class="row col-11 offset-1 " style="padding: 0px; padding-top:5px; font-size: 1rem; color: #fff; font-weight: 200; text-align:left;">          
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa-solid fa-phone"></span>&nbsp;886-2-2298-8939&nbsp;&nbsp;|&nbsp;&nbsp;<span class="fa-solid fa-fax"></span>&nbsp;886-2-2298-8938</div>
        </div>  
        <div class="row col-11 offset-1 " style="padding: 0px; padding-top:5px; font-size: 1rem; color: #fff; font-weight: 200; text-align:left;">          
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa-solid fa-envelope"></span>&nbsp;voice@fpe.com.tw</div>
        </div>                 
      </div>
    
      <div class="row col-12 setCenter bottomTextFooter" style="margin:0px;">
        <div class="col-8 conic setCenter" style="padding:5px; font-size: 1rem; color: #fff">Best Choice / Service First<br>嚴選設備  服務第一</div>
        <div class="col-12" style="font-size: 0.6rem; color: #000">Copyright © 2022 FPE Co., Ltd. All rights reserved.</div>
      </div>

    </div>
  </div>
  <!-- end wrap-->
</template>

<script>
var myID = "App";
import $ from "jquery";
import fontawesome from '@fortawesome/fontawesome'
import { faUser, faKey } from '@fortawesome/fontawesome-free-solid'
fontawesome.library.add(faUser, faKey)

export default {
  name: myID,
  setup() {
  },
  mounted() {
    window.onresize = () => {
      if (window.innerWidth > 1082) {
        console.log("---");
      }
    }
  },
  methods: {
    onResize() {
      
      if (window.innerWidth <= 1082) {
        this.menuMode = "vertical";
        document.getElementById("nav").style.display = "none";
        this.hamburger = false;
      } else {        
        this.menuMode = "horizontal";
        document.getElementById("nav").style.display = "inline-block";
        
      }
    },
    handleSubMenu() {
      if ($(window).width() <= "1082") {
        document.getElementById("subItem").classList.toggle("foo");
        
      }

      //window.location.replace("/products");
    },
    handleHamburger() {
      if (window.innerWidth <= 1082) {
        document.getElementById("nav").classList.toggle("toggleHamburger");
        // var line1 = document.getElementById('line1');
        document.getElementById("line1").classList.toggle("line1Close");
        //var line2 = document.getElementById('line2');
        document.getElementById("line2").classList.toggle("line2Close");
        //var line3 = document.getElementById('line3');
        document.getElementById("line3").classList.toggle("line3Close");   

        document.getElementById("logoBar").classList.toggle("tmp");

        //document.getElementById("logoBar").classList.toggle("showLogoBar");
      }

    },    
  },
};
</script>

<style>
.tmp {
  
}
.subItemPos {
  margin-top:23%;
}
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border: 0px solid #000;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 4;
  width: 40px;
  height: 30px;
}
.currentPageColor {
  color: rgb(247,150,70);
  text-decoration: underline;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  font-family: "Noto Sans TC", sans-serif !important;
}

.wrap {
  margin: 0;
  background-color: #fff;
  height: 100%;
}
.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 3;
}
.main {
  border: 0px red solid;
  min-height: calc(100% - 0px);
}
.topTextFooter {
  display: flex !important;
}  
.bottomTextFooter {
  display: none !important;
}
.welcome {
  width: 100%;
  height: 100%;
  padding: 5px 0;
  background-color: transparent;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13em;
}
.welcome .item {
  -webkit-text-stroke: 0em #000;
  color: #fff;
  font-size: 2.7em;
  font-weight: 900;
  margin-top: 1em;

  /*animation: oxxo 1s linear 0 1 normal;*/
  animation-name: oxxo;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
.welcome .item2 {
  -webkit-text-stroke: 0em #000;
  color: #fff;
  font-size: 2.5em;
  font-weight: 900;
  opacity: 0;
  /*animation: oxxo 1s linear 0 1 normal;*/
  animation-name: oxxo2;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}
@keyframes oxxo {
  from {
    margin-top: 1em;
  }
  to {
    margin-top: 0.4em;
  }
}
@keyframes oxxo2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer {
  /*margin-top: 50px;*/
  background-color: transparent;
  height: 125px;
  text-align: center;
}

@media screen and (min-width: 1083px) {
  .hamburger {
    display: none !important;
  }
  .hambugerLogo {
    display: none !important;
  }
  .nav {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: 85px !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 3;
    margin-top: 0px !important;
    background-color: #fff;
    transition: right 0.3s;
    border-top: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
  }
  .nav .item {
    border-bottom: 0px #d0d0d0 solid !important;
    width: 130px !important;
    height: 85px !important;
    float: left;
  }
  .nav .item:hover {
    background-color: #fff !important ;
    border-top: 3px solid #548dd4 !important;
  }
  .nav a {
    padding: 15px 10px 10px 10px !important;
  }
  .nav span {
    padding: 15px 10px 10px 10px !important;
  }

  /*下拉選單*/
  .foo {
    display: block !important;
    position: absolute !important;
    top: 60px !important;
    left: 0 !important;
    z-index: 3 !important;
    background-color: #fff;
    box-shadow: 0px 0px 10px #cccccc;

    width: 200px;
    padding: 5px;
  }
  .nav .itemGroup {
    position: relative;
  }
  .nav span:hover .subItem {
    display: block !important;
  }
  .subItem {
    display: none !important;
    position: absolute !important;
    top: 52.5px !important;
    left: 0 !important;
    z-index: 3 !important;
    /*background-color:rgba(62,71,84, 0.8) !important;*/
    background-color: transparent;
    /*box-shadow:0px 0px 10px #cccccc;*/

    width: 200px;
    padding: 5px;
  }
  .nav .subItem > a {
    background-color: rgba(62, 71, 84, 0.9) !important;
    width: 200px;
    border-bottom: 1px #ccc solid !important;
    margin-right: 20px;
    color: #fff !important;
  }
  .line {
    border-left: 1px solid red;
  }
  /*
.nav .subItem > a:after{
    content:">";
    color:#ccc;
    margin-left: 80px;
}*/

  /*
.itemGroup:hover .subItem{
   display: block !important;
  position: absolute;
  top:0;
  left: 0;
  z-index: 999;
  background-color: #000;
}*/
} /*end rwd 991*/

/*el-option style*/
.el-scrollbar {
  background-color: #fff;
  border: 1px solid #fff;
}

/*nav*/
.logo {
  position: fixed;
  top: 0;
  left: 25px !important;
  top: 3px !important
  /*z-index: 4;*/
}
.hambugerLogo {
  height: 50px;
  left: 25px !important;
  top: 3px !important
  /*z-index: 4;*/
}
.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  position: fixed;
  top: 0;
  right: -992px;
  width: 100%;
  z-index: 3;
  margin-top: 60px;
  background-color: #fff;
  transition: right 0.3s;
  border-top: 2px solid #548dd4;
  box-shadow: 3px 3px 4px 6px #cccccc;
  opacity: 0;
}
.nav .item {
  border-bottom: 1px #d0d0d0 solid;
  width: 100%;
}
.nav .item {
  /*background-color: #CE0000 ;*/
  background-color: #fff;
}

.nav .item > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  background-color: #fff;
}
.nav .item > a:hover {
  color: #548dd4;
}
.nav .subItem > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 400;
  font-size: 18px;
  background-color: #fff;
}
.nav .item :hover {
  color: #548dd4;
}
.nav a:hover {
  color: #548dd4;
}
.nav span:hover {
  cursor: pointer;
}

/*.nav a:hover{
  background-color: #CE0000;
}*/
.nav .item span {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding: 10px;
  /*position: relative;*/
}
nav .item span .subItem {
  display: none;
}

.foo {
  display: block !important;
  /*position: absolute;
  top:60px;
  left: 0;
  z-index: 999;*/
}

.hamburger:hover {
  cursor: pointer;
}
.toggleHamburger {
  right: 0;
  opacity: 1;
}

#line1 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line1Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(11px) rotate(45deg);
}

#line2 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line2Close {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
#line3 {
  display: block;
  width: 80%;
  height: 3px;
  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line3Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(-9px) rotate(-45deg);
}
.showLogoBar {
  /* display:none; */
  z-index: -1;
}

.setCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setRight {
  display: flex;
  align-items: center;
  justify-content: right;
}
.menuBar {
  display: flex;
  justify-content: right;
}
.removeHrefStyle {
  color: #000; 
  text-decoration: none !important;
}
.moreProducts {
  display: none
}
.itemGroupStyle{
  margin-top: -5px;
}
.logoBar {
position: absolute;
padding: 0px;
height: 40px;
margin-top:5px;
}
.logo {
position: absolute;
padding: 0px;
height: 60px;
margin-top:9px;
}
.footerStyle {
  height:170px;
}

.solconStyle {
  width:80%;
}
.technibusStyle {
  margin-top:10px;
  width:60%;
}
.MGCStyle {
  margin-top:10px;
  width:65%;
}
.squareDStyle {
  width:80%;
}
.ascoStyle {
  width:80%;
}    
.fanoxStyle {
  width:60%;
}    
.powerMeasuerStyle {
  width:80%;
}   
.schneiderStyle {
  width:80%;
}  
.subtitleStyle {
   font-size: 1.3rem; 
   font-weight: 700;
   text-align: left; 
   color: #3c3c3c; 
}
.productsTitle {
  font-size: 2rem;
}

@media (max-height: 618px) {
    /* .footerStyle {
    height:35%;
  } */
}
.engTitle {
  font-family: "Cambria", sans-serif !important;
  font-size:3.2rem !important;
}
.cntTitle {
  font-size:2.2rem;
}
.cntTitle2 {
  font-size:1.8rem;
}
.cntTitleMain {
  font-size:2.2rem !important;
}
.engTitleMain {
  font-family: "Cambria", sans-serif !important;
  font-size:2.8rem;
}
@media (max-width: 767px) {
  .contactDiv {
    padding-top: 20px;
    background-color: #353535;
  }
  .engTitle {
    font-family: "Cambria", sans-serif !important;
    font-size:2.2rem !important;
  }
  .cntTitle {
    font-size:1.3rem;
  }  
  .cntTitle2 {
    font-size:1.3rem;
  }  
}
@media (min-width: 1082px) {
  .hideMenuBar {
    display: none;
  }
}
@media (max-width: 1082px) {
  .subItemPos {
    margin-top:0px;
  }  

  .menuBar {
    display: block;
  }
  .moreProducts {
    display: block
  }
  .itemGroupStyle{
    margin-top: 0px !important;
  }
  .logoBar {
  position: absolute;
  padding: 0px;
  height: 80%;
  margin-top:-2px;
  }  
  .logo {
  position: absolute;
  padding: 0px;
  height: 50px;
  margin-top:10px;
  }    

}
@media (max-width: 1199px) {
  .hiddenDiv {
    display: none !important;
  }
  .topTextFooter {
    display: none !important;
  }  
  .bottomTextFooter {
    display: flex !important;
  }  
  .footerStyle {
    height:280px;
  }  
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border: 0px solid #000;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 4;
  width: 40px;
  height: 30px;
}    
}
@media (max-width: 768px) {

  .footerStyle {
    height:430px;
  }
.logo {
  position: fixed;
  top: 0;
  left: 10px !important;
  /*z-index: 4;*/
}
}
@media (max-width: 576px) {
  .topTextFooter {
    display: none !important;
  }  
  .bottomTextFooter {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .squareDStyle {
    width:50%;
  }
  .MGCStyle {
    margin-top:10px;
    width:45%;
  }
  .technibusStyle {
    margin-top:10px;
    width:80%;
  }  
  .solconStyle {
    width:50%;
  }  
  .ascoStyle {
    width:50%;
  }    
  .fanoxStyle {
    width:50%;
  }     
  .powerMeasuerStyle {
    width:50%;
  }     
  .schneiderStyle {
    width:50%;
  }    
.subtitleStyle {
   font-size: 1.3rem; 
   font-weight: 700;
   text-align: left; 
   color: #3c3c3c; 
}   
  .productsTitle {
    font-size: 1.5rem;
  }

}
.projectTitle2 {
  line-height:50px;
  padding-bottom:10px !important;
}

@media (max-width: 576px) {
.projectTitle2 {
  line-height:30px;
  margin-top:20px;
}  
  .squareDStyle {
    margin-top:10px;
    width:80%;
  }
  .MGCStyle {
    margin-top:10px;
    width:65%;
  }
  .technibusStyle {
      width:80%;
  }  
  .solconStyle {
    width:80%;
  }  
  .ascoStyle {
    width:80%;
  }      
  .fanoxStyle {
    width:60%;
  }     
  .powerMeasuerStyle {
    width:80%;
  }     
  .schneiderStyle {
    width:80%;
  }    
  .productsTitle {
    font-size: 1.5rem;
  }
  
}
@media (min-width: 1082px) {
  .mainTitleBox {
          height: 90px;
          width: 80px;
          position: relative;
          /* border-bottom: 2px solid #f51c40; */
          }

  .mainDiv:after {
    content : "";
    position: absolute;
    right    : 0;
    z-index: 100;
    top  : 0;
    margin-top: 18%;
    width  : 2px;
    height   : 50%;  /* or 100px */
    background: #ccc;
  }

}

</style>
<style scoped>
span.my-list li {
  list-style: none;
  display: inline-block;
  padding-bottom:2px; /*the space for the gradient*/
  background: linear-gradient(rgb(255, 255, 255),rgb(145, 145, 145)) center bottom; /*OR bottom right OR bottom left*/
  background-size: 0% 2px; /*width:0% height:2px*/
  background-repeat:no-repeat; /* Don't repeat !!*/
  transition: all 0.45s;
}

span.my-list li a {
  color: #ccc;
  text-decoration: none;
}


span.my-list li:hover {
  background-size: 100% 1px; /*width:100% height:2px*/
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.conic {
  position: relative;
  z-index: 0;
  width: 400px;
  height: 115px;
  /* margin: 20px; */
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;
}
.conic::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  /* background-color: #1a232a; */
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(transparent, rgb(168, 239, 255), transparent 30%);
  animation: rotate 4s linear infinite;
}
.conic::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: #000;
  border-radius: 5px;
}

.conic-demo::after {
  animation: opacityChange 5s infinite linear;
}

@keyframes opacityChange {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=border.css.map */

@media (max-width: 1199px) {
.conic {
  position: relative;
  z-index: 0;
  width: 400px;
  height: 75px;
  /* margin: 20px; */
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;
}  
}
</style>
