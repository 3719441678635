<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px;">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px;">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/products" class="removeHrefStyle">產品介紹</router-link> > <router-link to="/products/BusSystem" class="removeHrefStyle">匯流排系統</router-link> > <span class="currentPageColor">電力匯流排 </span></span>
    </div>

    <div class="row col-12" style=" margin:20px 0px 0px 0px; padding-right:8%;">
      <div class= "row col-md-3 offset-md-1 col-10 offset-1 setCenter itemStyle" style="border: solid 0px #ccc; padding:20px; padding-top:0px;">
        <div class="setCenter">
          <div class="col-12 " style="height:30%; padding:0px; padding-top:20px;width:80%;">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-1_SQUARED.png" style="width:100%;">
          </div>
        </div>
      </div>


      <div class= "row col-md-6 offset-md-1 col-10 offset-1 setCenter itemStyle" style="padding:10px;padding-top:30px;">
        <div class="setCenter" style="height:56px;padding-left:0px;">
          <div class="row col-12" style="padding:0px;margin:0px">
            <div class="col-12" style="height:10%;padding:0px;">
              <img src="../../../../img/GENERIC/LOGO/Square_D_Logo.png" style="width:280px;">
            </div>              
          </div>
        </div>      
        
        <div style="height:90%; padding:0px; padding-top:0px;margin-top:-2%">
          <div class="col-12" style="padding:0px; width:100%;">
            <span class="productsTitle" style="font-weight:700;height:70%;">Busway 電力匯流排</span>          
          </div>
          <div class="col-12" style="padding:0px; width:100%;padding-top:25px">
            <span>             

            Schneider 施耐德是世界500強的企業，集團生產的Busway產品超過億萬米，足以繞地球好幾周，Schneider Busway 銷量世界第一。
            <br>Square D生產Busway已將近80年(1942年開始)，目前生產的I-LINE系列busway也已超過60年歷史, 產品設計符合UL, NEMA, CSA, NFPA, IEC等多項國際標準並具有多項國際認證，銅匯流排全段鍍銀，鋁匯流排全段鍍錫，其電阻值及耗電量皆為同級產品最低的，符合世界節能減碳潮流。</span>
          </div>
        </div>        
      </div>


      <div class= "row col-md-5 offset-md-1 col-10 offset-1 setCenter itemStyle">        
        <div class="setCenter" style="height:90%; padding:0px;padding-left:20px">
          <div class="col-12" style="padding:0px; width:100%;">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px;;height:70%;color: rgb(49,133,155)">擴大市場全世界建設</span>          
            <br>Schneider是目前唯一在世界三大洲都有Busway製造工廠的集團，除了美國Ohio、歐洲法國，中國廣州已有製造工廠外，另有印度及馬來西亞工廠也將加入Busway的生產行列。            
          
          </div>
        </div>        
      </div>
      <div class= "row col-md-6 offset-md-0 col-10 offset-1 setCenter itemStyle" style="padding:20px">
        <div>
          <div class="col-12 " style="height:60%; padding:0px; width:100%;">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-2_SQUARED.png" style="width:100%;">
          </div>
        </div>
      </div>

      <div class= "row col-md-5 offset-md-1 col-10 offset-1 setCenter itemStyle" style="padding:20px">
        <div class="setCenter">
          <div class="col-12" style="height:60%; padding:0px; width:80%;">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-3_SQUARED.png" style="width:100%;">
          </div>
        </div>
      </div>
      <div class= "row col-md-6 offset-md-0 col-10 offset-1 setCenter itemStyle ">        
        <div class="setCenter" style="height:90%;">
          <div class="col-12" style="width:100%;">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px;;height:70%;color: rgb(49,133,155)">標準化的設計與發展</span>          
            <br>SQUARE-D匯流排槽具有方便擴充的延展性，可方便拆卸重組使用於未來新增及既設的案件中使用。符合UL、IEC、NEMA、ANSI等國際法規，最佳的產品可靠度。
            <br>SQUARE-D匯流排槽已在台銷售40餘年，售後服務有保證，不會發生市面上找不到經銷商及原廠的可能。現歸屬施耐德集團更形成最穩固的服務及品質保證。
            <br>本公司代理SQ-D匯流排已超過40年，經歷921大地震依舊沒有故障，安全第一可靠第一。
          </div>
        </div>        
      </div>

      <div class= "row col-11 offset-1 setCenter itemStyle ">        
        <div style="height:90%; padding:10px; padding-left:0px;">
          <div class="col-12" style="padding:10px; width:100%;">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px;;height:70%;color: rgb(49,133,155)">泛用型的插入口及高可靠度的分接器</span>          
            <br>SQUARE-D匯流排槽的可靠穩定及適合科技廠、飯店、醫療院所…等各類場所使用。插入式直線部份每三米可彈性選擇插入口數量，至多可設置10插入口，方便後續接線作業調整擴充彈性佳，分接器更可適用於60年前的busway以確保客戶擴充使用。所有插入口皆可廣泛的適用於15A-1600A插入式分接器。插入式分接器設計安全連鎖，當送電時無法打開箱門確保使用者安全。
          </div>
        </div>        
      </div>
      <div class= "row col-11 offset-1 setCenter itemStyle ">        
        <div style="height:90%; padding:10px; padding-left:0px">
          <div class="col-12" style="padding:10px; width:100%;">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px;;height:70%;color: rgb(49,133,155)">產品認證UL系列</span>          
            <br>UL系列銅排全段鍍銀，鋁排全段鍍錫，安全性高可用50年。無論銅鋁，電阻值皆較其他品牌更低，節能電費支出最省。外殼鍍鋅鋼板設計堅固耐用，有更好的防腐蝕性能。
          </div>
        </div>        
      </div>

      <div class= "row col-12 setCenter itemStyle ">        
        <div class="row col-md-5 col-10 offset-1 setCenter itemStyle" style="padding:0px; ">
          <div class="col-12 setCenter itemStyle setCenter">
            <div class="setCenter">
              <span style="font-weight:700; font-size:1.2rem; padding-top:20px;">［UL認證書］</span>
            </div>
          </div>                        
          <div class="col-12 setCenter">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-4_SQUARED_1_1.png" style="width:90%">
          </div>                                                         
        </div>        
   
        <div class="row col-md-5 col-10 offset-1 setCenter itemStyle" style="padding:0px; ">
          <div class="col-12 setCenter itemStyle setCenter">
            <div class="setCenter">
              <span style="font-weight:700; font-size:1.2rem; padding-top:20px;">［尺寸資料］</span>
            </div>
          </div>                        
          <div class="col-12 setCenter">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-4_SQUARED_2.png" style="width:90%">
          </div>                                                         
      </div>   

      <div class= "row col-11 offset-1 setCenter itemStyle ">        
        <div style="height:90%; padding:10px; padding-left:0px">
          <div class="col-12" style="padding:10px; width:100%;">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px;;height:70%;color: rgb(49,133,155)">產品認證IEC系列</span>          
            <br>IEC系列銅排全段鍍銀，鋁排接頭為銅鍍銀，鋁排接頭採用全新銅鋁合金設計，導電率較一般市售鋁匯流排更佳。銅鋁合金所有接觸面皆為鍍銀銅，確保高效能與降低投資成本。外殼鍍鋅鋼板設計堅固耐用，有更好的防腐蝕性能。
          </div>
        </div>        
      </div>              
      </div>

      <div class= "row col-12 setCenter itemStyle ">          
   
        <div class="row col-10 offset-1 setCenter itemStyle" style="padding:0px; ">
          <div class="col-12 setCenter itemStyle setCenter" >
            <div class="setCenter">
              <span style="font-weight:700; font-size:1.2rem; padding-top:20px;">[全系列額定電流通過KEMA認證 IEC439-6]</span>
            </div>
          </div>                        
          <div class="col-12 setCenter">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-5_SQUARED.png" style="width:90%">
          </div>    
        </div>       

        <div class="row col-10 offset-1 setCenter itemStyle" style="padding:0px; ">
          <div class="col-12 setCenter itemStyle setCenter" style="padding-top:10px">
            <div class="setCenter">
              <span style="font-weight:700; font-size:1.2rem; padding-top:20px;">[尺寸資料]</span>
            </div>
          </div>                        
          <div class="col-12 setCenter">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/001-6_SQUARED.png" style="width:90%">
          </div>    
        </div>        

      <div class= "row col-11 offset-1 setCenter itemStyle ">        
        <div style="height:90%; padding:10px; padding-left:0px;">
          <div class="col-12" style="padding:10px 0px;width:100%; ">
          <span style="font-weight:700; font-size:1.4rem; padding-top:20px; height:70%;color: rgb(49,133,155)">Square D Busway全台銷量第一</span>                      
            <br>台積電一廠自1990年啟用至今，已有20餘座廠採用Square D busway，電力匯流排配合製程24小時全年供電，至今未產生任何事故，台積電除台灣建廠使用，大陸廠松江、南京，甚至台積電美國及日本建廠也將選用Square D Busway。
            <br>半導體廠除台積電外，其他廠實績如聯電、美光、力晶、華邦、旺宏、京元電、漢磊、中美晶、南茂、穩懋、德州儀器…等。
            <br>高科技廠實績有友達、群創、台達電、晶電、合晶、智邦、友訊、矽統、元大、新日光、正達、高通、康寧玻璃…等。
            <br><br>國泰集團自第一棟高層建築仁愛國泰大樓1986年啟用，已有超過20棟大樓採用Square D Busway，更有10餘棟大樓經歷921大地震，至今仍安全送電。其餘建案實績有台灣證交所、台灣固網、中華電信、台北大巨蛋、台北車站、台北市政府、高雄市政府、桃園二航站、高雄小港機場、台北捷運、林口IBM、林口三井、遠百A13、新光三越A8、台北榮總、花蓮慈濟、台大癌醫中心…等。
            <br><br>台塑集團自1994年六輕建廠後，設備供電採用UL認證的Square D Busway，以保證供電品質安全可靠，除國內建廠採用外，如中國廈門、寧波、越南河靜、美國德州等也都陸續採用。
            <br><br>重工業建設除台塑集團的台塑、台化、南亞、台塑化、台塑重工採用Square D Busway以外其他實績如長春石化、杜邦、伊默克、瑞智、正隆、永豐餘、李長榮化工、厚生橡膠、國喬、三福氣體、中油煉製中心…等。            
          </div>
        </div>        
      </div>  
      </div>  


<div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.SecondItemStyle {
  margin-top: 20px;
}
.logoImg {
  width:280px
}
@media (max-width: 1199px) {
  .thirdItemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}

.removeHrefStyle {
  color: #3c3c3c; 
  text-decoration: none !important;
}
.itemStyle {
  margin-top: 20px;
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 991px) {
  .itemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
.subtitleStyle {
   font-size: 1.3rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}   
.hideImg {
  display:none;
} 
.logoImg {
  width:200px;
  margin-top:20px;
}
}

</style>