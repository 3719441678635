<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../img/CONTACT_US/title-contact-2000_400.png" style="width:100%; height: 200px;">
    <div class="text-block" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">聯絡我們</span><br><span class="engTitleMain">CONTACT US</span></div>
    </div>    
  </div>  
  <div class="row contentStyle" style="margin-top: 10px;">
    <div class="col-5 offset-1 contentImg bottomText" style="padding:30px">
      <!-- <img src="../img/ABOUT_US/FPE.png" style="height: 700px;"> -->
      <GMapMap
          :center="{lat: 25.068686471935017, lng: 121.45900484426738}"
          :zoom="18"
          map-type-id="terrain"
          style="width: 30vw; height: 20vw"
      >
      <GMapMarker
          :key="marker.id"
          v-for="marker in markers"
          :position="marker.position"
      />      
      </GMapMap>
    </div>

    <div class="row col-lg-5 offset-lg-0 col-10 offset-2">


      <div class="row col-12 contentStyle2 setCenter" style="text-align: center; padding:0px; padding-top:20px;">
        
        <div class="row col-12" style="padding: 0px; font-size: 1rem; color: #fff; font-weight: 200;">
          
          <div class="row col-12" style="text-align: left; padding: 0px;">

            <div class="row col-12" style="color: #fff !important; padding: 0px;">
              <div class="col-1 setCenter" style="padding:0px">
                <span>&nbsp;</span>
              </div>
              <div class="row col-10 contentStyle4" style="padding:0px;">
                <div class="col-12">
                  <span style="color:#3c3c3c;">&nbsp;&nbsp;<span class="contentStyle4">FULL POWER ENERGY CO., LTD.</span></span>
                </div>
                <div class="col-12">
                  <span class="contentStyle4" style="color:rgb(0,112,192)">&nbsp;&nbsp;第一電氣設備股份有限公司</span>
                </div>                
                <div class="col-12">
                  <span class="contentStyle3" style="color:rgb(0,112,192)">&nbsp;</span>
                </div>                    
              </div>
                            
            </div>



            <div class="row col-6" style="color: #fff !important; padding: 0px;">
              <div class="col-2 setCenter" style="background-color:rgb(52,58,64); padding:0px">
                <span class="fa-solid fa-phone" style="font-size:1.4rem;"></span>
              </div>
              <div class="row col-10 contentStyle2" style="padding:0px;">
                <div class="col-12">
                  <span style="color:#7f7f7f;">&nbsp;&nbsp;聯絡電話</span>
                </div>
                <div class="col-12">
                  <span style="color:#000;">&nbsp;&nbsp;886-2-2298-8939</span>
                </div>                
              </div>
                            
            </div>

            <div class="row col-6" style="color: #fff !important; padding: 0px;">
              <div class="col-2 setCenter" style="background-color:rgb(52,58,64); padding:0px">
                <span class="fa-solid fa-envelope" style="font-size:1.4rem;"></span>
              </div>
              <div class="row col-10 contentStyle2" style="padding:0px;">
                <div class="col-12">
                  <span style="color:#7f7f7f;">&nbsp;&nbsp;業務咨詢</span>
                </div>
                <div class="col-12">
                  <span style="color:#000;">&nbsp;&nbsp;voice@fpe.com.tw</span>
                </div>                
              </div>
                            
            </div>

            <div class="row col-6" style="color: #fff !important; padding: 0px; padding-top:10px">
              <div class="col-2 setCenter" style="background-color:rgb(52,58,64); padding:0px">
                <span class="fa-solid fa-fax" style="font-size:1.4rem;"></span>
              </div>
              <div class="row col-10 contentStyle2" style="padding:0px;">
                <div class="col-12">
                  <span style="color:#7f7f7f;">&nbsp;&nbsp;傳真電話</span>
                </div>
                <div class="col-12">
                  <span style="color:#000;">&nbsp;&nbsp;886-2-2298-8938</span>
                </div>                
              </div>
                            
            </div>

            <div class="row col-6" style="color: #fff !important; padding: 0px; padding-top:10px">
              <div class="col-2 setCenter" style="background-color:rgb(52,58,64); padding:0px">
                <span class="fa-solid fa-file-lines" style="font-size:1.4rem;"></span>
              </div>
              <div class="row col-10 contentStyle2" style="padding:0px;">
                <div class="col-12">
                  <span style="color:#7f7f7f;">&nbsp;&nbsp;型錄索取</span>
                </div>
                <div class="col-12">
                  <span style="color:#000;">&nbsp;&nbsp;voice@fpe.com.tw</span>
                </div>                
              </div>
                            
            </div>            
            <div class="row col-12 setCenter" style="color: #fff !important; padding: 0px; padding-top:10px; margin-left:-24px">
              <div class="col-1 setCenter mapIcon" style="background-color:rgb(52,58,64); padding:0px;">
                <span class="fa-solid fa-location-dot" style="font-size:1.4rem;"></span>
              </div>
              <div class="row col-11 contentStyle2" style="padding:0px;">
                <div class="col-12 addr" style="padding-right:0px;">
                  <span style="color:#7f7f7f;">聯絡地址:</span>
                </div>                   
                <div class="col-12 addr" style="padding-right:0px;">
                  <span style="color:#000;">248 新北市五股區五權二路37號</span>
                </div>                            
                <div class="col-12 addr contentStyle3" style="padding-right:0px;">
                  <span style="color:rgb(32,88,103);">No. 37, Wuquan 2nd Road, Wugu District, New Taipei City, Taiwan 248</span>
                </div>             
              </div>
                            
            </div>
            <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
          </div>

        </div>           
      </div>

    </div>
    <div class="col-5 offset-1 contentImg topText" style="padding-top:40px">
      <GMapMap
          :center="{lat: 25.068686471935017, lng: 121.45900484426738}"
          :zoom="18"
          map-type-id="terrain"
          style="width: 80vw; height: 100vw"
      >
      <GMapMarker
          :key="marker.id"
          v-for="marker in markers"
          :position="marker.position"
      />      
      </GMapMap>
    </div>
    <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>

  </div> 

</template>

<script>

export default {
  props: {

  },

  data() {
    return {
      center: {lat: 25.068686471935017, lng: 121.45900484426738},
      markers: [
        {
          id: 'dfsldjl3r',
          position: {
            lat: 25.068686471935017, lng: 121.45900484426738
          },
        }
      ]    
    };
  },
  components: {
  },
  methods: {
    
  },//end method
  created() {
  },
  mounted() {

  },//edn 
};
</script>
 
<style scoped>
.google-map {
  width: 100%;
  height: 400px;
}
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.contentStyle2 {
  font-size: 1.3rem;
  font-weight: 700;
}
  .contentStyle4 {
  font-size: 1.6rem;
  font-weight: 700;
  }  
.contentStyle3 {
  font-size: 1.4rem;
  font-weight: 700;
}
.setLeft {
  display: flex;
  align-items: center;
  justify-content: left;
}
.topText {
  display: none;
}  
.bottomText {
  display: block;
}  
.mapIcon {
  height: 100%;
}
@media (max-width: 1525px){
.contentStyle2 {
  font-size: 1rem;
  font-weight: 400;
}
  .contentStyle4 {
  font-size: 1.5rem;
  font-weight: 700;
  }  
.mapIcon {
height: 60%;
}
}

@media (max-width: 991px) {
  .contentStyle2 {
  font-size: 0.9rem;
  font-weight: 400;
  }
  .contentImg {
    display: none;
  }
.contentStyle3 {
  font-size: 1.2rem;
  font-weight: 700;
}  
.topText {
  display: block;
}  
.bottomText {
  display: none;
}  
.mapIcon {
height: 80%;
}
}
.addr {
  padding-left: 3%;
}
@media (max-width:767px) {
.contentStyle3 {
  font-size: 0.9rem;
  font-weight: 400;
}    
.addr {
  padding-left: 3%;
}  
}
.contentStyle3 {
  font-size: 1rem;
}
@media (max-width: 576px) {
.addr {
  padding-left: 5%;
}  
  .contentStyle4 {
  font-size: 1.2rem;
  font-weight: 400;
  }  
  .contentStyle2 {
  font-size: 0.7rem;
  font-weight: 400;
  }    
.contentStyle3 {
  font-size: 0.6rem;
}  
}
</style>