<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px; ">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px; background-color:rgb(242,242,242)">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <span class="currentPageColor">產品介紹</span> </span>
    </div>
    <div class="row col-12" style="margin-top:25px; margin:20px 0px 0px 0px;">
<div class= "row col-10 offset-1">

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/BusSystem" class= "row col-12 setCenter removeHrefStyle" style="padding:20px; background-color:rgb(242,242,242);">
          <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
            <img src="../../img/PRODUCTS/01_Busbar_System.png" style="width:90%;">
          </div>
          <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:18px;">
            <span>匯流排系統<br>Busbar System</span>
          </div>
        </router-link>
      </div> 

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/LowVoltageDistribution" class= "row col-12 setCenter removeHrefStyle" style="padding:20px; background-color:rgb(242,242,242);">
          <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
            <img src="../../img/PRODUCTS/02_Low_Voltage_Distribution.png" style="width:90%;">
          </div>
          <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:4px;">
            低壓配電及電控產品<br>Low Voltage Distribution <br>and Control Products
          </div>
        </router-link>
      </div> 

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/SoftStarter" class= "row col-12 setCenter removeHrefStyle" style="padding:20px; background-color:rgb(242,242,242);">
          <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
            <img src="../../img/PRODUCTS/03_Soft_Starter.png" style="width:90%;">
          </div>
          <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:18px;">
            緩衝啟動器<br>Soft Starter
          </div>
        </router-link>
      </div>       

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/ATS_TVSS_CTTS" class= "row col-12 setCenter removeHrefStyle" style="padding:20px; background-color:rgb(242,242,242);">
          <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
            <img src="../../img/PRODUCTS/04_ATS_TVSS_CTSS.png" style="width:80%;">
          </div>
          <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:18px;">
            自動切換開關/TVSS/<br>遙控切換開關<br>
          </div>
        </router-link>
      </div> 

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/Earth_Leakage_Relay" class= "row col-12 setCenter removeHrefStyle" style="padding:20px;background-color:rgb(242,242,242);">
        <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
          <img src="../../img/PRODUCTS/05_Earth_Leakage_Relay.png" style="width:80%;">
        </div>
        <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:18px;">
          漏電檢出電驛<br>Earth Leakage Relay
        </div>
        </router-link>
      </div> 

      <div class= "row col-xl-4 col-md-6 col-12 setCenter itemStyle" style=" padding:0px;background-color:#fff;margin:0px;">
        <router-link to="/products/Meter" class= "row col-12 setCenter removeHrefStyle" style="padding:20px; background-color:rgb(242,242,242);">
        <div class="col-12 setCenter" style="height:70%; padding:20px; width:100%; border-radius:20px 20px 0px 0px; border: 2px solid #ccc; border-bottom: none;background-color:#fff">
          <img src="../../img/PRODUCTS/06_Meter.png" style="width:80%;">
        </div>
        <div class="col-12 setCenter subtitleStyle" style="height:30%; border-radius:0px 0px 20px 20px; border: 2px solid #ccc; border-top: none; background-color:rgb(49,133,155); padding:18px;">
          電錶<br>Meter
        </div>
        </router-link>
      </div> 


</div>


      <div class= "col-12 setCenter logoAll" style="margin: 0px; padding: 0px;">
        <div class="col-12" style="width:100%;">
          <img src="../../img/PRODUCTS/logoline.png" style="width:100%;">
        </div>
      </div>           
      
    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.currentPageColor {
  color: rgb(247,150,70);
  text-decoration: underline;
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #fff; 
   text-align:center;
}
@media (max-width: 1140px) {
  .contentStyle {
    font-size: 1.2rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2rem;
  }
   .iconStyle {
    padding-top: 20px;
   }
  .text-block2 {
    position: absolute;
    top: 20%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.3rem;
  }   
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #fff; 
   text-align:center;
}  
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
.logoAll {
  margin-top:30px !important;
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
.subtitleStyle {
   font-size: 1.1rem; 
   text-align: left; 
   color: #fff; 
   text-align:center;
}    
.logoAll {
  margin-top:0px !important;
}
}


.effect{  
    position: relative;  
    box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -webkit-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -moz-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -o-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -ms-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    background: #fff;  
    text-align: center;  
    vertical-align: center;  
  
}  
.effect:after,.effect:before{  
    position: absolute;  
    content: '';  
    left:2%;  
    right: 2%;  
    top:50%;  
    bottom: 0%;  
    border-radius: 30%/20%;  
    box-shadow: 0 0 40px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    z-index: -1;  
}  
/*曲线阴影 end 翘角阴影begin*/  
.box{  
    width:980px;  
    list-style: none;  
    overflow: hidden;  
}  
.box li{  
    background: #fff;  
    float: left;  
    width:300px;  
    height: 200px;  
    margin: 20px 10px;  
    border:2px solid #efefef;  
    box-shadow: 0 0 40px rgba(0,0,0,0.1) inset;  
    position: relative;  
}  
.box li img{  
    display: block;  
    width:290px;  
    height: 190px;  
    margin:5px;  
}  
.box li:before{  
    content: '';  
    position: absolute;  
    left:3%;  
    right:3%;  
    height: 80%;  
    bottom: 3%;  
    z-index: -1;  
    transform:skew(-10deg) rotate(-3deg);  
    -webkit-transform:skew(-10deg) rotate(-3deg);  
    -moz-transform:skew(-10deg) rotate(-3deg);  
    -o-transform:skew(-10deg) rotate(-3deg);  
    -ms-transform:skew(-10deg) rotate(-3deg);  
    box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
}  
.box li:after{  
    content: '';  
    position: absolute;  
    left:3%;  
    right:3%;  
    height: 80%;  
    bottom: 3%;  
    z-index: -1;  
    transform:skew(10deg) rotate(3deg);  
    -webkit-transform:skew(10deg) rotate(3deg);  
    -moz-transform:skew(10deg) rotate(3deg);  
    -o-transform:skew(10deg) rotate(3deg);  
    -ms-transform:skew(10deg) rotate(3deg);  
    box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
}  
/*翘脚阴影end 相框begin*/  
.noborder{  
    /* margin: 10px auto;   */
    /* border-radius: 20px;   */
    box-shadow: ;  
    /* width: 500px;  
    height: 280px;   */
    overflow: hidden;  
    transition:box-shadow 0.3s ease-in;  
    -webkit-transition:box-shadow 0.3s ease-in;  
    -moz-transition:box-shadow 0.3s ease-in;  
    -o-transition:box-shadow 0.3s ease-in;  
    -ms-transition:box-shadow 0.3s ease-in;  
}  
.noborder img{  
    /* width: 500px;   */
    /* border-radius: 20px;   */
    transition:all 0.3s ease-in;  
    -webkit-transition:all 0.3s ease-in;  
    -moz-transition:all 0.3s ease-in;  
    -o-transition:all 0.3s ease-in;  
    -ms-transition:all 0.3s ease-in;  
}  
.noborder:hover{  
    box-shadow:0 0 20px rgba(184, 184, 184, 0.9);  
    zoom:0.905;  
  
}  
.noborder img:hover{  
    /*transform:scale(1.02); //用scale缩放四个角落有瑕疵，不知道怎么调*/  
    zoom:1.105;  
    background-color:black;
}  

/*相框end*/ 


</style>