<template>

  <div class="row contentStyle setCenter" style="margin-top: 100px;width:100%;padding:0px;margin:0px;overflow:hidden;">
    
    <vue-carousel class="col-12 carouselStyle" :data="slideImg" :controls="false" indicator-type="disc" style="padding:0px;" ref='carousel' :per-page="1" @slid='onPageChange'></vue-carousel>

    <div class="row col-12 setCenter" style="padding:0px; padding-left:2%; padding-right:2%; padding-top:20px; margin:0px;">

      <div class="row col-md-6 setCenter topText" style="padding-left:0px">
        <div class="col-12 setCenter" data-aos="fade-up" data-aos-once="false" id="subSlide11">
          <span class="imgText">What&nbsp;</span><span class="imgText imgText2">Do&nbsp;</span><span class="imgText">We&nbsp;</span><span class="imgText imgText2">Do ? </span>
        </div>
        <transition name="slide-fade">
          <div v-show="show" class="col-12 setCenter subImgText" data-aos="fade-up" data-aos-once="false" id="subSlide12">
            &nbsp;&nbsp;嚴選全球最高可靠度電氣產品
            <br>實現最高可靠度供電系統的理想
          </div> 
        </transition>
      </div>      

      <router-link to="/products/BusSystem" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;">
        <img src="../img/INDEX/inproduct_01.png" style="width:100%;">
      </router-link>
      <router-link to="/products/BusSystem" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;">
        <img src="../img/INDEX/inproduct_01.png" style="width:100%;">
      </router-link>        

      <div class="row col-md-6 setCenter bottomText" style="padding-left:0px;margin:0px;">
        <div class="col-12 setCenter" data-aos="fade-up" data-aos-once="false" id="subSlide21">
          <span class="imgText">What&nbsp;</span><span class="imgText imgText2">Do&nbsp;</span><span class="imgText">We&nbsp;</span><span class="imgText imgText2">Do ? </span>
        </div>
        <div class="col-12 setCenter subImgText" data-aos="fade-up" data-aos-once="false" id="subSlide22">
          &nbsp;&nbsp;嚴選全球最高可靠度電氣產品
          <br>實現最高可靠度供電系統的理想
        </div> 
      </div>


      <router-link to="/products/LowVoltageDistribution" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;">
        <img src="../img/INDEX/inproduct_02.png" style="width:100%;">
      </router-link>
      <router-link to="/products/LowVoltageDistribution" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;">
        <img src="../img/INDEX/inproduct_02.png" style="width:100%;">
      </router-link>      

      <router-link to="/products/SoftStarter" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;margin-right:0px;">
        <img src="../img/INDEX/inproduct_03.png" style="width:100%;">
      </router-link>
      <router-link to="/products/SoftStarter" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;margin-right:0px;">
        <img src="../img/INDEX/inproduct_03.png" style="width:100%;">
      </router-link>      


      <router-link to="/products/ATS_TVSS_CTTS" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;margin-right:0px;">
        <img src="../img/INDEX/inproduct_04.png" style="width:100%;">
      </router-link>
      <router-link to="/products/ATS_TVSS_CTTS" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;margin-right:0px;">
        <img src="../img/INDEX/inproduct_04.png" style="width:100%;">
      </router-link>      

      <router-link to="/products/Earth_Leakage_Relay" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;margin-right:0px;">
        <img src="../img/INDEX/inproduct_05.png" style="width:100%;">
      </router-link>
      <router-link to="/products/Earth_Leakage_Relay" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;margin-right:0px;">
        <img src="../img/INDEX/inproduct_05.png" style="width:100%;">
      </router-link>      

      <router-link to="/products/Meter" class="col-md-3 noborder setCenter bottomText" style="padding:2px; border:solid 2px #fff;background-color:#fff;margin-right:0px;">
        <img src="../img/INDEX/inproduct_06.png" style="width:100%;">
      </router-link>
      <router-link to="/products/Meter" class="col-6 setCenter noborder topText" style="padding:2px; border:solid 2px #fff; background-color:#fff;width:45%;margin-right:0px;">
        <img src="../img/INDEX/inproduct_06.png" style="width:100%;">
      </router-link>      
      
    </div>

    <div style="wight: 100%; margin:0px;background-color:rgb(69,69,69); " class="titleImg row subImg22">
      <div class="col-9">
        <div class="col-12 setCenter" style="margin-left:18.33%" data-aos="fade-up" data-aos-once="false" startEvent="load">
          <span class="imgText" style="color:#fff !important">Why&nbsp;</span><span class="imgText imgText2">Choose&nbsp;</span><span class="imgText" style="color:#fff">Us&nbsp;</span><span class="imgText imgText2" style="color:#000"> ? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div class="col-12 setCenter subImgText subImgText2" data-aos="fade-up" data-aos-once="false" style="text-align: center;margin-left:18.33%;color:#fff">
          <span>
            匯聚全球尖端成熟技術 透過在地化專業電氣團隊&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br>成就您最高可靠度供電系統&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="setCenter" style="margin-top:2%">
              <br><router-link to="/about" class="col-12" style="color: rgb(247,150,70) !important; text-decoration: none !important; "><span class="fa-solid fa-circle-arrow-right"></span>&nbsp;more&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
            </div>
          </span>
        </div>
      </div>
      <div class="col-3 board23" style=" text-align:right;">
        <img class="" src="../img/INDEX/board2_3.png" style="height:100%;">
      </div>
        
    </div> 

    <div class="row col-12 setCenter" style="padding:0px;">
      <div class="col-5 setCenter bottomBanner"  style="background-image: linear-gradient(to right, #fff, rgb(49,133,155));">&nbsp;</div>
      <div class="col-2 setCenter imgText3"  style="background-color: #fff; padding:0px">
        代理品牌
      </div>
      <div class="col-5 setCenter bottomBanner"  style="background-image: linear-gradient(to left, #fff, rgb(49,133,155));">&nbsp;</div>            
      
    </div>

    <div class="row col-12 setCenter" style="padding:0px;margin:0px; margin-top:20px">
      <img class="logoAll" src="../img/INDEX/logo_all.png">
    </div>
    <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
  </div> 
</template>
 
<script>

import VueCarousel from '@chenfengyuan/vue-carousel';


export default {
  data() {
    
    return {
      preIndex: 0,
      show: true,
      myWidth: window.innerWidth + 'px',
      slideImg: [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2" style="font-size:4rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:4rem;">嚴選設備，服務第一</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:6%;top:38%;text-align: left;color:#fff;" id="slide2Content"><span id="run2Text" class="engTitle2" style="font-size:4rem;">我們致力提供最佳服務</span></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:6%;top:33%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:4rem;">不斷堅持最好的品質</span></router-link>',
      ],      
    }
  },


  components: {
     VueCarousel
  },
  methods: {
      // carouselPageChange: function () {
      //   console.log(this.$refs.carousel.currentPage);
      // },    
    onPageChange: function(newIndex, oldIndex) {
      console.log( window.innerWidth)
      console.log('dd: '+newIndex, oldIndex)
      if (newIndex == 0)
        document.getElementById('slide1Content').innerHTML = '<span id="run1Text" class="engTitle2 setCenter" style="font-size:4rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.5rem; padding-right:10%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>';        
      else if (newIndex == 1)
        document.getElementById('slide2Content').innerHTML = '<span id="run2Text" class="engTitle2" style="font-size:4rem;">我們致力提供最佳服務</span>';
      else if (newIndex == 2)
        document.getElementById('runDiv3').innerHTML = '<span id="run3Text" class="engTitle2" style="font-size:4rem;">不斷堅持最好的品質</span>';
      
  if (window.innerWidth >= 1646){
      console.log("pageChange_1646");
      document.getElementById("logoBar").classList.remove("showLogoBar");
      //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:4rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.5rem; padding-right:10%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:4rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:4rem;">不斷堅持最好的品質</span></router-link>',
      ]    
      // document.getElementById('runDiv3').style.top = '33%';
  }
    
  else if (window.innerWidth < 1646 && window.innerWidth >= 1344){
    console.log("pageChange_1344");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:3.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:3.2rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:3.2rem;">不斷堅持最好的品質</span></router-link>',
      ]       
  }
    
  else if (window.innerWidth < 1344 && window.innerWidth >= 1082){    
    console.log("pageChange_1175");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:40%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:2.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:2.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:2.8rem;">不斷堅持最好的品質</span></router-link>',
      ]           
  }
    
  else if (window.innerWidth < 1082 && window.innerWidth >= 750){
    console.log("pageChange_750");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:35%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1.4rem; padding-right:5%;margin-top:-5%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:25%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.8rem;">不斷堅持最好的品質</span></router-link>',
      ]               
  }
    

  else if (window.innerWidth < 750){
    console.log("pageChange_749");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:12%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1rem; padding-right:5%;margin-top:-18%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:-1%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.2rem; text-align: center; padding:20px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:32%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.2rem;">不斷堅持最好的品質</span></router-link>',
      ]

      // document.getElementById('runDiv3').style.top = '20%';
  }         
    },      
  },//end method
  created() {
  },
  mounted() {
  if (window.innerWidth >= 1646){
      console.log("pageChange_1646");
      document.getElementById("logoBar").classList.remove("showLogoBar");
      //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:4rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.5rem; padding-right:10%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:4rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:4rem;">不斷堅持最好的品質</span></router-link>',
      ]    
      // document.getElementById('runDiv3').style.top = '33%';
  }
    
  else if (window.innerWidth < 1646 && window.innerWidth >= 1344){
    console.log("pageChange_1344");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:3.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:3.2rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:3.2rem;">不斷堅持最好的品質</span></router-link>',
      ]       
  }
    
  else if (window.innerWidth < 1344 && window.innerWidth >= 1082){    
    console.log("pageChange_1175");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:40%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:2.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:2.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:2.8rem;">不斷堅持最好的品質</span></router-link>',
      ]           
  }
    
  else if (window.innerWidth < 1082 && window.innerWidth >= 750){
    console.log("pageChange_750");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:35%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1.4rem; padding-right:5%;margin-top:-5%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:25%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.8rem;">不斷堅持最好的品質</span></router-link>',
      ]               
  }
    

  else if (window.innerWidth < 750){
    console.log("pageChange_749");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:12%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1rem; padding-right:5%;margin-top:-18%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:-1%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.2rem; text-align: center; padding:20px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:32%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.2rem;">不斷堅持最好的品質</span></router-link>',
      ]

      // document.getElementById('runDiv3').style.top = '20%';
  }  


  const self = this
    window.onresize = () => {
      console.log("aaaa: "+window.innerWidth);
  if (window.innerWidth >= 1646){
      console.log("pageChange_1646");
      document.getElementById("logoBar").classList.remove("showLogoBar");
      //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:4rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.5rem; padding-right:10%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:4rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:4rem;">不斷堅持最好的品質</span></router-link>',
      ]    
      // document.getElementById('runDiv3').style.top = '33%';
  }
    
  else if (window.innerWidth < 1646 && window.innerWidth >= 1344){
    console.log("pageChange_1344");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:47%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:3.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2.2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:3.2rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:3.2rem;">不斷堅持最好的品質</span></router-link>',
      ]       
  }
    
  else if (window.innerWidth < 1344 && window.innerWidth >= 1082){    
    console.log("pageChange_1175");
    document.getElementById("logoBar").classList.remove("showLogoBar");
    //document.getElementById("subItem").classList.remove("foo");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:40%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:2.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:2rem; padding-right:5%;">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:38%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:2.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:2.8rem;">不斷堅持最好的品質</span></router-link>',
      ]           
  }
    
  else if (window.innerWidth < 1082 && window.innerWidth >= 750){
    console.log("pageChange_750");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:35%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.8rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1.4rem; padding-right:5%;margin-top:-5%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:25%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.8rem; text-align: center; padding:30px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:35%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.8rem;">不斷堅持最好的品質</span></router-link>',
      ]               
  }
    

  else if (window.innerWidth < 750){
    console.log("pageChange_749");
    document.getElementById("logoBar").classList.remove("showLogoBar");
      this.slideImg= [
        '<div><img src="/run-1.png" class="d-block img" style="width:100%"/></div><div data-aos="fade-up" data-aos-once="false" class="row text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:12%;text-align: left;color:#fff;" id="slide1Content"><span id="run1Text" class="engTitle2 setCenter" style="font-size:1.2rem;">———&ensp; 第一電氣 &ensp;———</span><span id="run1Text" class="engTitle2 setRight" style="font-size:1rem; padding-right:5%;margin-top:-18%">嚴&ensp;選&ensp;設&ensp;備&ensp;，&ensp;服&ensp;務&ensp;第&ensp;一&ensp;</span></div>',
        '<div><router-link to="/project"><img src="/run-2.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/project" data-aos="fade-up" data-aos-once="false" class="text-block titleStyle setCenter" style="text-decoration: none !important;width:100%; height: 200px; position: absolute;top:-1%;text-align: left;color:#fff;" id="slide2Content"><div id="run2Text" class="engTitle2 setCenter" style="font-size:1.2rem; text-align: center; padding:20px 0px; background:rgba(0,0,0,0.5); width:30%;">我們致力提供<br>最佳服務</div></router-link>',
        '<div><router-link to="/products"><img src="/run-3.png" class="d-block img" style="width:100%"/></router-link></div><router-link to="/products" data-aos="fade-up" data-aos-once="false" id="runDiv3" class="text-block titleStyle" style="text-decoration: none !important;width:100%; height: 200px; position: absolute; left:8%;top:32%;text-align: left;color:#fff;"><span id="run3Text" class="engTitle2" style="font-size:1.2rem;">不斷堅持最好的品質</span></router-link>',
      ]

      // document.getElementById('runDiv3').style.top = '20%';
  }  

      return (() => {
        self.myWidth = window.innerWidth + 'px'
      })()
    }

    // $("#firstImg").className += "carousel-item-start active";
    // $("#secondImg").className += "carousel-item-next carousel-item-start";
  },//edn 
};
</script>

<style scoped>
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  font-family: "Noto Sans TC", sans-serif !important;
}
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */

.text-block {
  position: absolute;
  top: 20%;
  font-size: 2.5rem;
  color: white;
}
.contentStyle {
  font-size: 1.2rem;
}
.slideBtn {
  padding:5px !important
}
.imgText {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Noto Sans TC", sans-serif !important;
}
.imgText2 {
  color: rgb(75,172,198);
  font-family: "Noto Sans TC", sans-serif !important;
}
.subImgText {
  font-size: 1.4rem;
  font-family: "Noto Sans TC", sans-serif !important;
}
.topText {
  display: none;
}  
 .topText2 {
    display: none;
  }  
.midText {
  display: none;
}
.bottomText {
  display: block;
}
.imgStyle {
  width: 100%;
}
.subImgText2 {
  margin-top: 10px
}    
.imgText3 {
  font-size: 2.3rem;
  font-weight: 700;
}
.logoAll {
   width:50%;
}
@media (max-width: 1300px) {
  .text-block {
  position: absolute;
  top: 18%;
  color: white;
}
}
@media (max-width:1100px) {
    .moreText {
    margin-top: -20px;
  }  
}
@media (max-width: 991px) {
  .contentStyle {
  font-size: 1rem;
  }
  .contentImg {
    display: none;
  }
  .slideBtn {
    padding:2px !important
  }  
  .imgText {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "Noto Sans TC", sans-serif !important;
  }  
  .subImgText {
    font-size: 1rem;
    font-family: "Noto Sans TC", sans-serif !important;
  }  

}
@media (max-width:1340px) {
    .bottomText2 {
    display: none;
  }    
  .midText {
    display: block;
    /* height: 115%;
    padding-bottom:20px; */
  }  
 .topText2 {
    display: none;
  }    
}
@media (max-width:1623px) {
  /* .midText {
    display: block;
    height: 115%;
    padding-bottom:20px;
  } */

  .imgText3 {
    font-size: 1.8rem;
    font-weight: 700;
  }  
}
.subImg22 {
  padding:45px 0px; 
  margin:25px !important;
}
.board23 {
  height:170px;
  margin-top:-40px;
}
@media (max-width:1000px) {
.board23 {
  height:120px;
  margin-top:-40px;
}  
}
.carouselStyle {
  margin-top:85px;
}

.bottomBanner {
  height:15px;
}

@media (max-width:767px) {
.bottomBanner {
  height:5px;
}
.carouselStyle {
  margin-top:70px;
}

.board23 {
  height:100px;
  margin-top:-20px;
}  
.subImg22 {
  padding:25px 0px; 
  margin:15px !important;
}  
  .topText {
    display: block;
    padding-left:20px;
  }  
  .topText2 {
    display: block;
  }    
  .midText {
    display: none;
  }    
  .bottomText {
    display: none;
  }  
  .imgStyle {
    /* width: 50%; */
    padding-top:20px
  }
  .subImgText2 {
    font-size: 0.8rem;
    /* margin-top: -10px */
  }    
  .imgText3 {
    font-size: 1rem;
    font-weight: 700;
  }
  .logoAll {
   width:80%;
}
.text-block {
  position: absolute;
  top: 25%;
}
    .moreText {
    margin-top: -5%;

  }  
.engTitle2 {
  font-family: "Cambria", sans-serif !important;
  font-size:2rem;
}  
.slide1Font {
  font-family: "Cambria", sans-serif !important;
  font-size:2rem;
}  

}
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.engTitle2 {
  font-family: "Cambria", sans-serif !important;
  font-size:4.5rem;
}
.slide1Font {
  font-family: "Cambria", sans-serif !important;
  font-size:4.5rem;
}  

.effect{  
    position: relative;  
    box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -webkit-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -moz-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -o-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    -ms-box-shadow:0 4px 4px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.2) inset;  
    background: #fff;  
    text-align: center;  
    vertical-align: center;  
  
}  
.effect:after,.effect:before{  
    position: absolute;  
    content: '';  
    left:2%;  
    right: 2%;  
    top:50%;  
    bottom: 0%;  
    border-radius: 30%/20%;  
    box-shadow: 0 0 40px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 0 20px rgba(0,0,0,0.8);  
    z-index: -1;  
}  
/*曲线阴影 end 翘角阴影begin*/  
.box{  
    width:980px;  
    list-style: none;  
    overflow: hidden;  
}  
.box li{  
    background: #fff;  
    float: left;  
    width:300px;  
    height: 200px;  
    margin: 20px 10px;  
    border:2px solid #efefef;  
    box-shadow: 0 0 40px rgba(0,0,0,0.1) inset;  
    position: relative;  
}  
.box li img{  
    display: block;  
    width:290px;  
    height: 190px;  
    margin:5px;  
}  
.box li:before{  
    content: '';  
    position: absolute;  
    left:3%;  
    right:3%;  
    height: 80%;  
    bottom: 3%;  
    z-index: -1;  
    transform:skew(-10deg) rotate(-3deg);  
    -webkit-transform:skew(-10deg) rotate(-3deg);  
    -moz-transform:skew(-10deg) rotate(-3deg);  
    -o-transform:skew(-10deg) rotate(-3deg);  
    -ms-transform:skew(-10deg) rotate(-3deg);  
    box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
}  
.box li:after{  
    content: '';  
    position: absolute;  
    left:3%;  
    right:3%;  
    height: 80%;  
    bottom: 3%;  
    z-index: -1;  
    transform:skew(10deg) rotate(3deg);  
    -webkit-transform:skew(10deg) rotate(3deg);  
    -moz-transform:skew(10deg) rotate(3deg);  
    -o-transform:skew(10deg) rotate(3deg);  
    -ms-transform:skew(10deg) rotate(3deg);  
    box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -webkit-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -moz-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -o-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
    -ms-box-shadow: 0 8px 10px rgba(0,0,0,0.8);  
}  
/*翘脚阴影end 相框begin*/  
.noborder{  
    /* margin: 10px auto;   */
    /* border-radius: 20px;   */
    box-shadow: ;  
    /* width: 500px;  
    height: 280px;   */
    overflow: hidden;  
    transition:box-shadow 0.3s ease-in;  
    -webkit-transition:box-shadow 0.3s ease-in;  
    -moz-transition:box-shadow 0.3s ease-in;  
    -o-transition:box-shadow 0.3s ease-in;  
    -ms-transition:box-shadow 0.3s ease-in;  
}  
.noborder img{  
    /* width: 500px;   */
    /* border-radius: 20px;   */
    transition:all 0.3s ease-in;  
    -webkit-transition:all 0.3s ease-in;  
    -moz-transition:all 0.3s ease-in;  
    -o-transition:all 0.3s ease-in;  
    -ms-transition:all 0.3s ease-in;  
}  
.noborder:hover{  
    box-shadow:0 0 10px rgba(184, 184, 184, 0.9);  
    zoom:0.455;  
  
}  
.noborder img:hover{  
    /*transform:scale(1.02); //用scale缩放四个角落有瑕疵，不知道怎么调*/  
    zoom:1.155;  
    background-color:black;
}  

/*相框end*/ 
</style>