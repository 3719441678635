//import Vue from 'vue'
//import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from "vue-router";

//0. 啟用 Vue全局使用Router
// createApp.use(VueRouter)
// 1. 定義 (路由) 组件，供main.js使用

//Home
import Home from "./components/Home.vue";

//About
import About from "./components/About.vue";

//Contact
import Contact from "./components/Contact.vue";

//Products Index
import Products from "./components/Products/Products.vue"

//匯流排系統
import BusSystem from "./components/Products/BusSystem/BusSystem.vue";
//匯流排系統 - 產品一
import BusSystemProduct1 from "./components/Products/BusSystem/Item/BusSystemProduct1.vue";
//匯流排系統 - 產品二
import BusSystemProduct2 from "./components/Products/BusSystem/Item/BusSystemProduct2.vue";
//匯流排系統 - 產品三
import BusSystemProduct3 from "./components/Products/BusSystem/Item/BusSystemProduct3.vue";
//匯流排系統 - 產品四
import BusSystemProduct4 from "./components/Products/BusSystem/Item/BusSystemProduct4.vue";
//匯流排系統 - 產品五
import BusSystemProduct5 from "./components/Products/BusSystem/Item/BusSystemProduct5.vue";

//低壓配電及電控產品
import LowVoltageDistribution from "./components/Products/LowVoltageDistribution/LowVoltageDistribution.vue";
//低壓配電及電控產品 - 產品一
import LowVoltageDistributionProduct1 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct1.vue";
//低壓配電及電控產品 - 產品二
import LowVoltageDistributionProduct2 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct2.vue";
//低壓配電及電控產品 - 產品三
import LowVoltageDistributionProduct3 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct3.vue";
// //低壓配電及電控產品 - 產品四
import LowVoltageDistributionProduct4 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct4.vue";
// //低壓配電及電控產品 - 產品五
import LowVoltageDistributionProduct5 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct5.vue";
// //低壓配電及電控產品 - 產品二
import LowVoltageDistributionProduct6 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct6.vue";
// //低壓配電及電控產品 - 產品二
import LowVoltageDistributionProduct7 from "./components/Products/LowVoltageDistribution/Item/LowVoltageDistributionProduct7.vue";

// 緩衝啟動器
import SoftStarter from "./components/Products/SoftStarter/SoftStarter.vue";
//緩衝啟動器 - 產品一
import SoftStarterProduct1 from "./components/Products/SoftStarter/Item/SoftStarterProduct1.vue";
// 緩衝啟動器 - 產品二
import SoftStarterProduct2 from "./components/Products/SoftStarter/Item/SoftStarterProduct2.vue";
// 緩衝啟動器 - 產品三
import SoftStarterProduct3 from "./components/Products/SoftStarter/Item/SoftStarterProduct3.vue";
// 緩衝啟動器 - 產品4
import SoftStarterProduct4 from "./components/Products/SoftStarter/Item/SoftStarterProduct4.vue";

//自動切換開關/TVSS/遙控切換開關
import ATS_TVSS_CTTS from "./components/Products/ATS_TVSS_CTTS/ATS_TVSS_CTTS.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品一
import ATS_TVSS_CTTSProduct1 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct1.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品2
import ATS_TVSS_CTTSProduct2 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct2.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品3
import ATS_TVSS_CTTSProduct3 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct3.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品4
import ATS_TVSS_CTTSProduct4 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct4.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品5
import ATS_TVSS_CTTSProduct5 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct5.vue";
//自動切換開關/TVSS/遙控切換開關 - 產品6
import ATS_TVSS_CTTSProduct6 from "./components/Products/ATS_TVSS_CTTS/Item/ATS_TVSS_CTTSProduct6.vue";

//漏電檢出電驛
import Earth_Leakage_Relay from "./components/Products/Earth_Leakage_Relay/Earth_Leakage_Relay.vue";
import Earth_Leakage_RelayProduct1 from "./components/Products/Earth_Leakage_Relay/Item/Earth_Leakage_RelayProduct1.vue";
import Earth_Leakage_RelayProduct2 from "./components/Products/Earth_Leakage_Relay/Item/Earth_Leakage_RelayProduct2.vue";
import Earth_Leakage_RelayProduct3 from "./components/Products/Earth_Leakage_Relay/Item/Earth_Leakage_RelayProduct3.vue";

//電表
import Meter from "./components/Products/Meter/Meter.vue";
import MeterProduct1 from "./components/Products/Meter/Item/MeterProduct1.vue";
import MeterProduct2 from "./components/Products/Meter/Item/MeterProduct2.vue";
import MeterProduct3 from "./components/Products/Meter/Item/MeterProduct3.vue";
import MeterProduct4 from "./components/Products/Meter/Item/MeterProduct4.vue";

// 工程實績
import Project from "./components/Project/Project.vue"
// 工程實績 items
import ASCO_TVSS from "./components/Project/Item/ASCO_TVSS.vue"
import ASCO_ATS_CTTS from "./components/Project/Item/ASCO_ATS_CTTS.vue"
import MGC from "./components/Project/Item/MGC.vue"
import SQD from "./components/Project/Item/SQD.vue"
import SOLCON_MID from "./components/Project/Item/SOLCON_MID.vue"
import SOLCON_LOW from "./components/Project/Item/SOLCON_LOW.vue"
import SQUARE_D from "./components/Project/Item/SQUARE_D.vue"
import TECHNIBUS from "./components/Project/Item/TECHNIBUS.vue"
import SQUARE_D_BUSWAY from "./components/Project/Item/SQUARE_D_BUSWAY.vue"



// 2. 定義路由
const routes = [
  // 配置路由
  {
    path: "/",
    name: "index",
    component: Home,
  },
  {
    path: "/home", // 連接路徑 <router-link to="/home">Home</router-link>
    name: "home", // 路由名稱，router-link 的 to 属性传一个对象，<router-link :to="{ name: 'home'}">Home</router-link>
    component: Home, //對應的組件
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/products",
    name: "products",
    component: Products,
  },
  //匯流排
  {
    path: "/products/BusSystem",
    name: "BusSystem",
    component: BusSystem,
  },
  {
    path: "/products/BusSystem/BusSystemProduct1",
    name: "BusSystemProduct1",
    component: BusSystemProduct1,
  },
  {
    path: "/products/BusSystem/BusSystemProduct2",
    name: "BusSystemProduct2",
    component: BusSystemProduct2,
  },
  {
    path: "/products/BusSystem/BusSystemProduct3",
    name: "BusSystemProduct3",
    component: BusSystemProduct3,
  },
  {
    path: "/products/BusSystem/BusSystemProduct4",
    name: "BusSystemProduct4",
    component: BusSystemProduct4,
  },
  {
    path: "/products/BusSystem/BusSystemProduct5",
    name: "BusSystemProduct5",
    component: BusSystemProduct5,
  },
  //低壓配電及電控產品
  {
    path: "/products/LowVoltageDistribution",
    name: "LowVoltageDistribution",
    component: LowVoltageDistribution,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct1",
    name: "LowVoltageDistributionProduct1",
    component: LowVoltageDistributionProduct1,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct2",
    name: "LowVoltageDistributionProduct2",
    component: LowVoltageDistributionProduct2,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct3",
    name: "LowVoltageDistributionProduct3",
    component: LowVoltageDistributionProduct3,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct4",
    name: "LowVoltageDistributionProduct4",
    component: LowVoltageDistributionProduct4,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct5",
    name: "LowVoltageDistributionProduct5",
    component: LowVoltageDistributionProduct5,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct6",
    name: "LowVoltageDistributionProduct6",
    component: LowVoltageDistributionProduct6,
  },
  {
    path: "/products/LowVoltageDistribution/LowVoltageDistributionProduct7",
    name: "LowVoltageDistributionProduct7",
    component: LowVoltageDistributionProduct7,
  },
  //緩衝啟動器
  {
    path: "/products/SoftStarter",
    name: "SoftStarter",
    component: SoftStarter,
  },
  {
    path: "/products/SoftStarter/SoftStarterProduct1",
    name: "SoftStarterProduct1",
    component: SoftStarterProduct1,
  },
  {
    path: "/products/SoftStarter/SoftStarterProduct2",
    name: "SoftStarterProduct2",
    component: SoftStarterProduct2,
  },
  {
    path: "/products/SoftStarter/SoftStarterProduct3",
    name: "SoftStarterProduct3",
    component: SoftStarterProduct3,
  },
  {
    path: "/products/SoftStarter/SoftStarterProduct4",
    name: "SoftStarterProduct4",
    component: SoftStarterProduct4,
  },
  //自動切換開關/TVSS/遙控切換開關
  {
    path: "/products/ATS_TVSS_CTTS",
    name: "ATS_TVSS_CTTS",
    component: ATS_TVSS_CTTS,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct1",
    name: "ATS_TVSS_CTTSProduct1",
    component: ATS_TVSS_CTTSProduct1,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct2",
    name: "ATS_TVSS_CTTSProduct2",
    component: ATS_TVSS_CTTSProduct2,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct3",
    name: "ATS_TVSS_CTTSProduct3",
    component: ATS_TVSS_CTTSProduct3,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct4",
    name: "ATS_TVSS_CTTSProduct4",
    component: ATS_TVSS_CTTSProduct4,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct5",
    name: "ATS_TVSS_CTTSProduct5",
    component: ATS_TVSS_CTTSProduct5,
  },
  {
    path: "/products/ATS_TVSS_CTTS/ATS_TVSS_CTTSProduct6",
    name: "ATS_TVSS_CTTSProduct6",
    component: ATS_TVSS_CTTSProduct6,
  },
  //漏電檢出電驛
  {
    path: "/products/Earth_Leakage_Relay",
    name: "Earth_Leakage_Relay",
    component: Earth_Leakage_Relay,
  },
  {
    path: "/products/Earth_Leakage_Relay/Earth_Leakage_RelayProduct1",
    name: "Earth_Leakage_RelayProduct1",
    component: Earth_Leakage_RelayProduct1,
  },
  {
    path: "/products/Earth_Leakage_Relay/Earth_Leakage_RelayProduct2",
    name: "Earth_Leakage_RelayProduct2",
    component: Earth_Leakage_RelayProduct2,
  },
  {
    path: "/products/Earth_Leakage_Relay/Earth_Leakage_RelayProduct3",
    name: "Earth_Leakage_RelayProduct3",
    component: Earth_Leakage_RelayProduct3,
  },
  //電表
  {
    path: "/products/Meter",
    name: "Meter",
    component: Meter,
  },
  {
    path: "/products/Meter/MeterProduct1",
    name: "MeterProduct1",
    component: MeterProduct1,
  },
  {
    path: "/products/Meter/MeterProduct2",
    name: "MeterProduct2",
    component: MeterProduct2,
  },
  {
    path: "/products/Meter/MeterProduct3",
    name: "MeterProduct3",
    component: MeterProduct3,
  },
  {
    path: "/products/Meter/MeterProduct4",
    name: "MeterProduct4",
    component: MeterProduct4,
  },
  // 工程實績
  {
    path: "/Project",
    name: "Project",
    component: Project,
  },  
  {
    path: "/Project/ASCO_TVSS",
    name: "ASCO_TVSS",
    component: ASCO_TVSS,
  },  
  {
    path: "/Project/ASCO_ATS_CTTS",
    name: "ASCO_ATS_CTTS",
    component: ASCO_ATS_CTTS,
  },  
  {
    path: "/Project/MGC",
    name: "MGC",
    component: MGC,
  },  
  {
    path: "/Project/SQD",
    name: "SQD",
    component: SQD,
  }, 
  {
    path: "/Project/SOLCON_MID",
    name: "SOLCON_MID",
    component: SOLCON_MID,
  }, 
  {
    path: "/Project/SOLCON_LOW",
    name: "SOLCON_LOW",
    component: SOLCON_LOW,
  },   
  {
    path: "/Project/SQUARE_D",
    name: "SQUARE_D",
    component: SQUARE_D,
  },  
  {
    path: "/Project/TECHNIBUS",
    name: "TECHNIBUS",
    component: TECHNIBUS,
  },
  {
    path: "/Project/SQUARE_D_BUSWAY",
    name: "SQUARE_D_BUSWAY",
    component: SQUARE_D_BUSWAY,
  },  
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },    
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    hidden: true,
  },  
];
// 3. 匯出配置檔
// const router = new createRouter({
//   history: createWebHistory(), // history为必填项
//   routes,
// });
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;

/*
export default new VueRouter({
    mode: 'history',
     routes 
  })*/
export { router };
