<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../img/ABOUT_US/title-about-2000_400.jpg" style="width:100%; height: 200px;">
    <div class="text-block" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">公司介紹</span><br><span class="engTitleMain">ABOUT US</span></div>
    </div>    
  </div>  
  <div class="row contentStyle" style="margin-top: 10px;">
    <div id="aboutContent" class="row col-lg-7 offset-lg-1 col-10 offset-1" >
      
        <span data-aos="fade-up" data-aos-once="false">
          <br>第一電氣設備股份有限公司成立於1987年，是一家全球知名電氣品牌設備代理商，代理中低壓匯流排槽、馬達啟動器及保護開關、低壓分電盤、乾式變壓器、模殼式無熔絲開關、照明用匯流排、集合式電錶、自動切換開關、中低壓緩衝啟動器…。
          <br><br>主要銷售對象包括科學園區各廠、商辦大樓、塑化廠、民營電廠、機械廠、醫院、鋼鐵廠等。
          <br><br>本公司以踏實穩健的經營、高品質的設備及最有效率的售服團隊為客戶的電力設備提供最佳的解決方案。
        </span>
      
      
        <!-- <div style="border-bottom: solid 5px rgb(147,124,176); width: 10px; height:20px; padding-bottom: 10px;" data-aos="fade-up" data-aos-once="false"></div> -->
        <span style="color: rgb(147,124,176)" data-aos="fade-up" data-aos-once="false">▃</span>
      
        <span class="" style="color: rgb(49,133,155);" data-aos="fade-up" data-aos-once="false">
          <br>Full Power Energy Co., Ltd. was established in 1987. We are the authorized distributor for many global top electrical products companies. Products are medium and low voltage busways, motor starters and protection switches, low voltage distribution panels, dry-type transformers, molded case circuit breakers, lighting busbars, power meters, automatic transfer switches, and medium and low voltage soft starters.
          <br><br>Our major customers are semiconductor and electronic plants in the Science Park, commercial office buildings, petrochemical plants, private power plants, machinery plants, healthcare facilities, and iron and steel factories, etc.
          <br><br>Full Power Energy is a dependable and steady business partner. We are committed to providing the highest quality electrical products, the most efficient service team, and the best solutions for our customers.
        </span>

      
    </div>
    <div id="aboutImg" class="col-lg-4 offset-lg-0 col-11 offset-1 setCenter">
      <img class="picStyle" src="../img/ABOUT_US/FPE.png">
    </div>
    <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
    document.getElementById('aboutContent').innerHTML = 
            '<span data-aos="fade-up" data-aos-once="false"><br>第一電氣設備股份有限公司成立於1987年，是一家全球知名電氣品牌設備代理商，代理中低壓匯流排槽、馬達啟動器及保護開關、低壓分電盤、乾式變壓器、模殼式無熔絲開關、照明用匯流排、集合式電錶、自動切換開關、中低壓緩衝啟動器…。<br><br>主要銷售對象包括科學園區各廠、商辦大樓、塑化廠、民營電廠、機械廠、醫院、鋼鐵廠等。<br><br>本公司以踏實穩健的經營、高品質的設備及最有效率的售服團隊為客戶的電力設備提供最佳的解決方案。</span><span style="color: rgb(147,124,176)" data-aos="fade-up" data-aos-once="false">▃</span><span class="" style="color: rgb(49,133,155);" data-aos="fade-up" data-aos-once="false"><br>Full Power Energy Co., Ltd. was established in 1987. We are the authorized distributor for many global top electrical products companies. Products are medium and low voltage busways, motor starters and protection switches, low voltage distribution panels, dry-type transformers, molded case circuit breakers, lighting busbars, power meters, automatic transfer switches, and medium and low voltage soft starters.<br><br>Our major customers are semiconductor and electronic plants in the Science Park, commercial office buildings, petrochemical plants, private power plants, machinery plants, healthcare facilities, and iron and steel factories, etc.<br><br>Full Power Energy is a dependable and steady business partner. We are committed to providing the highest quality electrical products, the most efficient service team, and the best solutions for our customers.</span>'
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.contentStyle {
  font-size: 1.2rem;
}
.picStyle {
  height: 700px;
}
.conCht {
  font-size: 1.5rem;
}
.conEng {
  font-size: 1.5rem;
  text-align:justify;
  text-justify: auto;
}
@media (max-width: 991px) {
  .contentStyle {
  font-size: 1rem;
  }
  .picStyle {
  height: 500px;
  }
.conCht {
  font-size: 1.2rem;
}
.conEng {
  font-size: 1.1rem;
  text-align:break-word;;
  text-justify: auto;
  padding-right: 10px;;
}  
}

</style>