<template>
  <div style="padding-top:85px; wight: 100%;" class="titleImg">
    <img src="../../../../img/PRODUCTS/title-product.png" style="width:100%; height: 200px;">
    <div class="text-block titleStyle" style="width:100%; height: 200px;">
      <div style="text-align: center "><span class="cntTitleMain">產品介紹</span><br><span class="engTitleMain">Products</span></div>
    </div>    
  </div>  

  
  <div class="row contentStyle" style="padding-top:25px;">
    <div class="col-xl-11 offset-xl-1 col-12 offset-0" style="z-index: 999;padding-left:50px">
      <span style=" font-size: 1.2rem"><router-link to="/home" class="removeHrefStyle">首頁</router-link> > <router-link to="/products" class="removeHrefStyle">產品介紹</router-link> > <router-link to="/products/BusSystem" class="removeHrefStyle">匯流排系統</router-link> > <span class="currentPageColor">高低壓完全絕緣分相式匯流排 </span></span>
    </div>

    <div class="row col-12" style="margin-top:25px; margin:20px 0px 0px 0px;">
      <div class= "row col-md-4 offset-md-1 col-10 offset-1 setCenter itemStyle" style="border: solid 0px #ccc; padding:20px; padding-top:0px;">
        <div>
          <div class="col-12" style="height:60%; padding:0px; width:100%;">
            <img src="../../../../img/PRODUCTS/01_Busbar_System/01_introduce/002-1_MOSER_GLASER_BUSWAY.png" style="width:100%;">
          </div>
        </div>
      </div>

      <div class= "row col-md-6 offset-md-1 col-10 offset-1 setCenter itemStyle" style="padding:10px;padding-top:30px;">
        <div class="setCenter" style="height:56px;padding-left:0px;">
          <div class="row col-12" style="padding:0px;margin:0px">
            <div class="col-12" style="height:10%;padding:0px;">
              <img src="../../../../img/GENERIC/LOGO/MGC.png" style="width:280px;">
            </div>              
          </div>
        </div>           



        <div style="height:90%; padding:0px; padding-top:0px;margin-top:-3%">
          <div class="col-12" style="padding:0px; width:100%;">
            <span class="productsTitle" style="font-weight:700; padding-top:20px;;height:70%">瑞士 MGC 高低壓完全絕緣分相式匯流排</span>
          </div>
          <div class="col-12" style="padding:0px; width:100%;padding-top:25px">
            <span>             
              ●&nbsp;RIP環氧樹脂浸漬紙絕緣技術，皺紋紙－乾燥和真空下注入環氧樹脂
              <br>●&nbsp;電壓範圍 1kV–245kV
              <br>●&nbsp;電流範圍 1000A–10000A 
              <br>●&nbsp;最長直線長度為 10 米
              <br>●&nbsp;兩段匯流排間的連結使用完全密閉的保護套筒Sleeve
              <br>●&nbsp;導體－使用高導電率的銅棒(管)或鋁棒(管)
              <br>●&nbsp;接地屛蔽保護－鋁箔附銅網 (> 8000A, 1s)
              <br>●&nbsp;匯流排末端－獨特設計達到最佳電位梯度分配
              <br>●&nbsp;獨特導電片(Contact Disc) 解決銅鋁介面問題
              <br>●&nbsp;提供連接各知名品牌開關設備接頭(ABB、西門子、施耐德、 Pfisterer 、 HICO等)
              <br>●&nbsp;外殼採用IP68等級抗UV耐腐蝕的Polyamide材料，可適用於較惡劣的環境</span>
            

          </div>
          <div class="col-12" style="padding:0px; width:100%;">&nbsp;</div>
        </div>        
      </div>

    </div>
  </div> 
</template>
 
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },//end method
  created() {
  },
  mounted() {
  },//edn 
};
</script>
 
<style scoped>
/* Container holding the image and the text */
.titleImg {
  position: relative;
}

/* Bottom right text */
.text-block {
  position: absolute;
  top: 40%;
  font-size: 2.5rem;
  color: white;
}
.text-block2 {
  position: absolute;
  top: 28%;
  padding: 0px 20px 0px 20px;
  color: white;
  font-size:1.8rem;
}
.contentStyle {
  font-size: 1.4rem;
}
.removeHrefStyle {
  color: #000 !important; 
  text-decoration: none !important;
}
.SecondItemStyle {
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .thirdItemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 1450px) {
  .contentStyle {
    font-size: 1.2rem;
  }
  .text-block {
    font-size: 2.5rem;
  }
}
@media (max-width: 1270px) {
  .contentStyle {
    font-size: 1rem;
    padding-top: 20px;
  }
    .text-block {
    font-size: 2.2rem;
  }
  .text-block2 {
    position: absolute;
    top: 15%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1.5rem;
  }     
}
.subtitleStyle {
   font-size: 1.2rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}

.removeHrefStyle {
  color: #3c3c3c; 
  text-decoration: none !important;
}
.itemStyle {
  margin-top: 20px;
}
@media (max-width: 950px) {
  .text-block2 {
    position: absolute;
    top: 10%;
    padding: 0px 20px 0px 20px;
    color: white;
    font-size:1rem;
  }   
  
}
@media (max-width: 991px) {
  .itemStyle {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .text-block2 {  
    position: absolute;
    top: 20%;
    padding: 0px 30px 0px 30px;
    color: white;
    font-size:1.3rem;
  } 
.subtitleStyle {
   font-size: 1.3rem; 
   text-align: left; 
   color: #3c3c3c; 
   text-align:center;
}    
}
</style>